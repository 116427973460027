/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import BackButtonModal from "../../../../../../Forms/BackButtonModal";
import "../../BankruptcyDisclosure.scss";
import { CCB_FORM_ID } from "../../../constants";
import {
  formatMoney,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../../common/Functions";
import "../../../BondDisclosureForm/AddDRPForms/BondDisclosureAddDRPDispositionDetailsForm/BondDisclosureDispositionDetailsForm.scss";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../../store/actions";

const BankruptcyDisclosureCompromiseDetails = ({
  updateCCBForm,
  backFormId,
  updateTempBankruptcyDRP,
  tempBankruptcyDRP,
  setNotApplicable,
  hasSIPA,
  isOrg,
}) => {
  const dispatch = useDispatch();
  const [compCreditorName, setCompCreditorName] = useState("");
  const [compCreditorNameError, setCompCreditorNameError] = useState(false);
  const [compOriginalAmountOwed, setCompOriginalAmountOwed] = useState("");
  const [compOriginalAmountOwedError, setCompOriginalAmountOwedError] =
    useState(false);
  const [compOriginalAmountOwedErrorText, setCompOriginalAmountOwedErrorText] =
    useState("");
  const [compTermsReachedText, setCompTermsReachedText] = useState("");
  const [compTermsReachedTextError, setCompTermsReachedTextError] =
    useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBackModal, toggleShowBackModal] = useState(false);
  const [currentFormChanged, hasCurrentFormChanged] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  const handleToggleBackModal = (value) => {
    if (currentFormChanged) {
      toggleShowBackModal(value);
    } else {
      handleBack();
    }
  };
  const handleNext = () => {
    const tmpDRP = {
      ...tempBankruptcyDRP,
      compCreditorName: compCreditorName,
      compOriginalAmountOwed: compOriginalAmountOwed,
      compTermsReachedText: compTermsReachedText,
    };

    updateTempBankruptcyDRP(tmpDRP);

    if (hasSIPA(tmpDRP)) {
      setNotApplicable([]);
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_PAGE_5);
    } else {
      setNotApplicable(() => (!isOrg ? [5] : [6]));
      updateCCBForm(CCB_FORM_ID.ADD_BANKRUPTCY_DRP_COMMENTS);
    }

    hasCurrentFormChanged(false);
  };

  const handleBack = () => {
    updateCCBForm(backFormId);
  };
  const handleChange = ({ target: { id, value } }) => {
    hasCurrentFormChanged(true);
    switch (id) {
      case "compCreditorName":
        setCompCreditorName(value);
        break;
      case "compOriginalAmountOwed":
        setCompOriginalAmountOwed(formatMoney(value));
        break;
      case "compTermsReachedText":
        setCompTermsReachedText(value);
        break;
      default:
        break;
    }
  };

  const onBlur = ({ target: { id, value } }) => {
    switch (id) {
      case "compCreditorName":
        setCompCreditorNameError(value.trim().length < 2);
        break;
      case "compOriginalAmountOwed":
        const isMoneyError =
          parseInt(value?.replaceAll(",", "")) < 0 ||
          parseInt(value?.replaceAll(",", "")) > 100000000 ||
          value?.length < 1;
        const moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000 ||
          parseInt(value?.replaceAll(",", "")) < 0
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter Original Amount Owed"
            : "";

        setCompOriginalAmountOwedError(isMoneyError);
        setCompOriginalAmountOwedErrorText(moneyErrMessage);
        break;
      case "compTermsReachedText":
        setCompTermsReachedTextError(value.trim().length < 2);
        break;
      default:
        break;
    }

    setFocusedState(false);
  };

  const allFilled = () => {
    let greaterCheck = false;
    if (compOriginalAmountOwed !== "") {
      let num = parseInt(compOriginalAmountOwed?.replaceAll(",", ""));
      greaterCheck = num <= 100000000;
    } else {
      greaterCheck = true;
    }
    return (
      compCreditorName.trim().length > 1 &&
      greaterCheck &&
      compOriginalAmountOwed.trim().length > 0 &&
      compTermsReachedText.trim().length > 1
    );
  };
  const noErrors = () => {
    return (
      !compCreditorNameError &&
      !compOriginalAmountOwedError &&
      !compTermsReachedTextError
    );
  };

  useEffect(() => {
    setIsFormComplete(allFilled() && noErrors());
  });

  useEffect(() => {
    if (
      tempBankruptcyDRP?.compCreditorName &&
      tempBankruptcyDRP?.compOriginalAmountOwed &&
      tempBankruptcyDRP?.compTermsReachedText
    ) {
      setCompCreditorName(tempBankruptcyDRP?.compCreditorName);
      setCompOriginalAmountOwed(tempBankruptcyDRP?.compOriginalAmountOwed);
      setCompTermsReachedText(tempBankruptcyDRP?.compTermsReachedText);
      setIsFormComplete(true);
      //       if (editIndex !== null) {
      //   hasCurrentFormChanged(false);
      // } else {
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
      // }
    }
  }, []);
  useEffect(() => {
    if (currentFormChanged || !noErrors()) {
      hasCurrentFormChanged(true);
      dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
    }
  }, [currentFormChanged, noErrors(), dispatch]);

  return (
    <div>
      <div className="bankruptcy-form-wrapper">
        <br />
        <div className="row">
          <div className="col-md-12">
            <label
              id="compCreditorNameLabel"
              className="field-label"
              htmlFor="compCreditorName"
            >
              Name of Creditor: *
            </label>
            <input
              style={{ width: "60%" }}
              id="compCreditorName"
              type="text"
              className={
                compCreditorNameError
                  ? "form-control is-invalid"
                  : "form-control"
              }
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={() => setFocusedState(true)}
              value={compCreditorName}
              maxLength={200}
              minLength={2}
              autoComplete="off"
            ></input>
            <div id="compCreditorNameError" className="invalid-feedback">
              Please enter Name of Creditor
            </div>
          </div>
        </div>
      </div>
      <div className="bond-disposition-details-form-wrapper">
        <div className="row" style={{ marginTop: "15px" }}>
          <div className="col-md-4">
            <label id="payoutAmountLabel" className="field-label">
              Original Amount Owed: *
            </label>
            <div
              className={
                compOriginalAmountOwedError
                  ? "input-group is-invalid"
                  : "input-group"
              }
              id="payoutAmountInput"
              style={{ width: "75%" }}
            >
              <div className="input-group-prepend">
                <span
                  className="input-group-text input-group-text-pad-right"
                  id="payoutAmount$"
                >
                  $
                </span>
              </div>
              <input
                type="text"
                id="compOriginalAmountOwed"
                className={
                  compOriginalAmountOwedError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                spellCheck="false"
                maxLength={11}
                value={compOriginalAmountOwed}
                onBlur={onBlur}
                onChange={handleChange}
                onKeyPress={onlyNumeric}
                onPaste={pasteCommaDelimited}
                onFocus={() => setFocusedState(true)}
                autoComplete="off"
              />
            </div>
            <div
              id="compOriginalAmountOwedError"
              className={
                compOriginalAmountOwedError
                  ? "show invalid-feedback"
                  : "invalid-feedback"
              }
              style={{ marginTop: "10px" }}
            >
              {compOriginalAmountOwedErrorText}
            </div>
          </div>
        </div>
      </div>
      <div className="bankruptcy-form-wrapper">
        <div
          className="action-date-explanation-container"
          style={{ marginTop: "20px" }}
        >
          <label
            htmlFor="compTermsReachedText"
            className="field-label"
            id="compTermsReachedTextLabelId"
          >
            Terms/Compromise reached with creditor: *
          </label>
          <textarea
            style={{ width: "90%" }}
            id="compTermsReachedText"
            className={
              compTermsReachedTextError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={200}
            minLength={2}
            rows="3"
            cols={500}
            value={compTermsReachedText}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={() => setFocusedState(true)}
          />
          <div id="compTermsReachedTextError" className="invalid-feedback">
            Please enter Terms/Compromise reached with creditor
          </div>
        </div>

        <div className="row backbutton-drp-bankruptcy">
          <div className="col-md-12 buttons">
            <span
              id="bankruptcyAddDRPBack"
              className="back-button"
              onClick={() => {
                handleToggleBackModal(true);
              }}
            >
              <strong id="bankruptcyDisclBack">Back</strong>
            </span>
            <Button
              disabled={!isFormComplete || isFocusedState}
              id="bankruptcyAddDRPNext"
              style={{ marginRight: "10px" }}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </div>
        <BackButtonModal
          isOpen={showBackModal}
          closeBackModal={() => {
            handleToggleBackModal(false);
          }}
          backClick={handleBack}
        />
      </div>
    </div>
  );
};
export default BankruptcyDisclosureCompromiseDetails;
