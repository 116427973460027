function ConfAppDirectDeposit(props) {
  return (
    <div id="confidential-direct-deposit">
      <div className="col-7 row mm-spacing--minor">
        <h3>Direct Deposit Information</h3>
      </div>
      {props.dd_info.compensationtype === 1 && (
        <>
          <div className="row">
            <div className="col-5">
              <h5 id="conf_comptype_text">Compensation Type*</h5>
            </div>
            <div id="compensationtype" className="col-5 data">
              {props.dd_info.compensationtype === 1
                ? "MassMutual Only"
                : props.same_bank_info === true
                ? "MassMutual Only"
                : "MassMutual and Affiliated Broker Dealer (MMLIS Registered Business)"}
            </div>
            <div className="col-5">
              <h5 id="conf_accounttype_text">Account Type*</h5>
            </div>
            <div id="mmaccounttype" className="col-5 data">
              {props.dd_info.mmaccounttype === 1 ? "Checking" : "Savings"}
            </div>
            <div className="col-5">
              <h5 id="conf_routingnum_text">Routing Number*</h5>
            </div>
            <div id="mmroutingnumber" className="col-3 data">
              {props.dd_info.mmroutingnumber}
            </div>

            <div className="col-5">
              <h5 id="conf_accountnum_text">Account Number*</h5>
            </div>
            <div id="mmaccountnumber" className="col-3 data">
              {props.dd_info.mmaccountnumber}
            </div>
          </div>
        </>
      )}
      {props.dd_info.compensationtype === 2 && (
        <>
          <div className="row">
            <div className="col-3">
              <h5 id="conf_comptype_mm">Compensation Type*</h5>
            </div>
            <div
              id="compensation_type"
              className="col-3 data"
              style={
                props.same_bank_info === true
                  ? {
                      marginBottom: "20px",
                    }
                  : {}
              }
            >
              {props.same_bank_info === true ? (
                <>
                  MassMutual and Affiliated Broker Dealer (MMLIS Registered
                  Business)
                </>
              ) : (
                <>MassMutual</>
              )}
            </div>
            {props.same_bank_info === false && (
              <>
                <div className="col-3">
                  <h5 id="conf_comptype_abd">Compensation Type*</h5>
                </div>
                <div
                  id="compensation_type"
                  className="col-3 data"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  Affiliated Broker Dealer (MMLIS Registered Business)
                </div>
              </>
            )}
            <div className="col-3">
              {props.same_bank_info === true ? (
                <h5 id="conf_routingnum_same">Routing Number*</h5>
              ) : (
                <h5 id="conf_accounttype_same">Account Type*</h5>
              )}
            </div>
            <div
              id={
                props.same_bank_info === true
                  ? "mmroutingnumber"
                  : "mmaccounttype"
              }
              className="col-3 data"
            >
              {props.same_bank_info === true ? (
                <>{props.dd_info.mmroutingnumber}</>
              ) : (
                <>
                  {props.dd_info.mmaccounttype === 1 ? "Checking" : "Savings"}
                </>
              )}
            </div>
            {props.same_bank_info === false && (
              <>
                <div className="col-3">
                  <h5 id="conf_accounttype_diff">Account Type*</h5>
                </div>
                <div id="brokaccounttype" className="col-3 data">
                  {props.dd_info.brokaccounttype === 1 ? "Checking" : "Savings"}
                </div>
              </>
            )}
            <div className="col-3">
              {props.same_bank_info === true ? (
                <h5 id="conf_accounttype_diff">Account Type*</h5>
              ) : (
                <h5 id="conf_routingnum_diff">Routing Number*</h5>
              )}
            </div>
            <div
              id={
                props.same_bank_info === true
                  ? "mmaccounttype"
                  : "mmroutingnumber"
              }
              className="col-3 data"
            >
              {props.same_bank_info === true ? (
                <>
                  {props.dd_info.mmaccounttype === 1 ? "Checking" : "Savings"}
                </>
              ) : (
                <>{props.dd_info.mmroutingnumber}</>
              )}
            </div>
            {props.same_bank_info === false && (
              <>
                <div className="col-3">
                  <h5 id="conf_routingnum_diff2">Routing Number*</h5>
                </div>
                <div id="brokroutingnumber" className="col-3 data">
                  {props.dd_info.brokroutingnumber}
                </div>
              </>
            )}
            <div className="col-3">
              <h5 id="conf_accountnum_diff2">Account Number*</h5>
            </div>
            <div id="mmaccountnumber" className="col-3 data">
              {props.dd_info.mmaccountnumber}
            </div>
            {props.same_bank_info === false && (
              <>
                <div className="col-3">
                  <h5 id="conf_accountnum_diff3">Account Number*</h5>
                </div>
                <div id="brokaccountnumber" className="col-3 data">
                  {props.dd_info.brokaccountnumber}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ConfAppDirectDeposit;
