import React, { Component } from "react";
import { ProgressBar } from "react-bootstrap";
import { Button } from "react-blueprint-library";
import ExitModal from "./Forms/BackButtonModal";
import { FORM_ID } from "../../constants/Forms";
import "./StartApplicationNav.scss";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../constants/Contract";

class StartApplicationNavAgentEmeritus extends Component {
  constructor(props) {
    super(props);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleExitClick = this.handleExitClick.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.state = {
      showModal: false,
      disableReviewSignBtn: true,
      progress: 0,
    };
  }

  handleExitClick() {
    window.location = "/";
  }

  handleModalClose() {
    this.setState({ showModal: false });
  }

  handleShowModal() {
    if (this.props.currentFormChanged === true || this.props.showLogoutModal) {
      this.setState({ showModal: true });
    } else {
      this.handleExitClick();
    }
  }

  moreInfoClick = (e) => {
    e.stopPropagation();
  };
  componentDidMount() {
    this.setProgress();
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setProgress();
    }
  }

  setProgress() {
    const { contracttypedesc, includePSR } = this.props;

    let trueCount = 0;
    let numberOfIndicators = 0;

    //Remove PSRIndicator if there is no PSR
    let passedInProps = this.props;
    if (includePSR === false) {
      passedInProps = Object.fromEntries(
        Object.entries(passedInProps).filter(
          ([key]) => key !== "psrCompleteInd"
        )
      );
    }

    for (let item in passedInProps) {
      if (item.includes("Ind")) {
        numberOfIndicators += 1;
        if (passedInProps[item]) {
          trueCount += 1;
        }
      }
    }

    const progress = Math.round(
      100 - ((numberOfIndicators - trueCount) / numberOfIndicators) * 100
    );

    this.setState({
      progress: progress,
      disableReviewSignBtn: !(progress === 100),
    });
  }
  render() {
    return (
      <div id="startAppNavCAS">
        <div className="progress-bar-section">
          <div className="row">
            <div className="col-12">
              <h3 id="startAppNavProgressHeader">Application Progress</h3>
            </div>
          </div>
          <div className="row">
            <div
              id="startAppNavProgressBar"
              aria-label="Progress Bar Div NavCAS"
              className="col-7"
            >
              <ProgressBar
                id="progressBar"
                aria-label="Progress Bar NavCAS"
                now={this.state.progress}
              />
            </div>

            <div id="startAppNavProgressBarLabel" className="col-5">
              <strong>
                <span id="progressComplete">
                  {this.state.progress}% Complete
                </span>
              </strong>
            </div>
          </div>
        </div>
        <div className="nav-items">
          <div className="row">
            <div className="col-12">
              <div id="nav3" className="nav-button">
                {this.props.currentForm === FORM_ID.PERSONAL_INFO ? (
                  <>
                    <span id="current3" className="current-indicator ">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Personal Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Personal Information</div>
                )}
                {this.props.pInfoCompleteInd === true && (
                  <div id="check3">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                )}
                {this.props.pInfoCompleteInd === false && (
                  <div id="summary3">
                    <span className="nav-summary-text" id="summary_text_3">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav4" className="nav-button">
                {this.props.currentForm === FORM_ID.RESIDENTIAL_ADDRESS ? (
                  <>
                    <span id="current4" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Residential Address</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Residential Address</div>
                )}
                {this.props.resAddrCompleteInd ? (
                  <div id="check4">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                ) : (
                  <div id="summary4">
                    <span className="nav-summary-text" id="summary_text_4">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="nav5" className="nav-button">
                {this.props.currentForm === FORM_ID.BUSINESS_INFO ? (
                  <>
                    <span id="current5" className="current-indicator">
                      CURRENT
                    </span>
                    <br />
                    <div className="nav-name">
                      <strong>Business Information</strong>
                    </div>
                  </>
                ) : (
                  <div className="nav-name">Business Information</div>
                )}
                {this.props.busAddrCompleteInd ? (
                  <div id="check5">
                    <span className="icon-answer-correct icon-sm"></span>
                  </div>
                ) : (
                  <div id="summary5">
                    <span className="nav-summary-text" id="summary_text_5">
                      0/1
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {this.props.contracttypedesc !==
            CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY && (
            <div className="row">
              <div className="col-12">
                <div id="nav9" className="nav-button">
                  {this.props.currentForm === FORM_ID.DIRECT_DEPOSIT ? (
                    <>
                      <span id="current9" className="current-indicator">
                        CURRENT
                      </span>
                      <br />
                      <div className="nav-name">
                        <strong>Direct Deposit Information</strong>
                      </div>
                    </>
                  ) : (
                    <div className="nav-name">Direct Deposit Information</div>
                  )}
                  {this.props.directDepositCompleteInd === true && (
                    <div id="check9">
                      <span className="icon-answer-correct icon-sm"></span>
                    </div>
                  )}
                  {this.props.directDepositCompleteInd === false && (
                    <div id="summary9">
                      <span className="nav-summary-text" id="summary_text_9">
                        0/1
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              <div>
                <Button
                  id="reviewAndSignBtn"
                  variant="secondary"
                  disabled={this.state.disableReviewSignBtn}
                  onClick={() => this.props.toggleForm(FORM_ID.REVIEW_SIGN_1)}
                >
                  Review & Sign
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 btn-link-wrapper">
            <div>
              <Button
                id="exitBtn"
                variant="link"
                onClick={() => {
                  this.handleShowModal();
                }}
              >
                <strong>Exit</strong>
              </Button>
            </div>
          </div>

          <ExitModal
            isOpen={this.state.showModal}
            closeBackModal={this.handleModalClose}
            handleClose={this.handleModalClose}
            backClick={this.handleExitClick}
          ></ExitModal>
        </div>
      </div>
    );
  }
}
export default StartApplicationNavAgentEmeritus;
