/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import InputMask from "react-input-mask";
import { COUNTRIES } from "../../../constants/countries";
import { FORM_ID } from "../../../constants/Forms";
import Moment from "moment";
import BackModal from "../Forms/BackButtonModal";
import EducationRequirementsModal from "../Forms/EducationRequirementsModal";
import {
  updateCandidateEducation,
  updateInternEducation,
} from "../../../api/makeAPIRequest";
import { clickFunc, isValidDate } from "../../../common/Functions";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";

const EducationForm = (props) => {
  const [formHasError, setFormHasError] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [highestEducation, setHighestEducation] = useState("");
  const [highestEducationId, setHighestEducationId] = useState("");
  const [degree, setDegree] = useState("");
  const [degreeId, setDegreeId] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [majorIn, setMajorIn] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [cntrlWithFocus, setCntrlWithFocus] = useState("");
  const [showBackModal, setShowBackModal] = useState(false);
  const [showEduReqModal, setShowEduReqModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currEnrolled, setCurrEnrolled] = useState("");
  const [yearEnrolled, setYearEnrolled] = useState("");
  const [overallGpa, setOverallGpa] = useState("");
  const [majorGpa, setMajorGpa] = useState("");
  const [disableMajorIn, setDisableMajorIn] = useState(false);
  const [showState, setShowState] = useState(false);
  const [DEGREES, setDEGREES] = useState([]);
  const [EDUCATIONLEVEL, setEDUCATIONLEVEL] = useState([]);
  const [loading, setLoading] = useState(false);
  var hasError = false;
  var hasChange = false;

  const onBlur = async (event) => {
    let obj_error;

    if (
      cntrlWithFocus.includes("option") &&
      cntrlWithFocus !== event.target.id
    ) {
    } else {
      if (event.target.id.includes("education_id")) {
        obj_error = document.getElementById("education_error");

        if (event.target.innerText.trim(" ").length === 0) {
          event.target.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          var educationCtrl = document.getElementById(event.target.id);
          if (event.target.className !== "dropdown-item") {
            event.target.removeAttribute("style");
          } else {
            educationCtrl.style.border = "1px solid #6b748e";
          }
        }
      }
      if (
        event.target.id.includes("education_option") &&
        cntrlWithFocus !== "education_option"
      ) {
        obj_error = document.getElementById("education_error");
        let ctrlToValidate = document.getElementById("education_id");
        if (highestEducation.length === 0) {
          ctrlToValidate.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          if (ctrlToValidate.className !== "dropdown-item") {
            ctrlToValidate.removeAttribute("style");
          } else {
            let genderCtrl2 = document.getElementById("gender_id");
            genderCtrl2.style.border = "1px solid #6b748e";
          }
        }
      }
      if (event.target.id.includes("degree_name")) {
        obj_error = document.getElementById("degree_name_error");

        if (event.target.innerText.trim(" ").length === 0) {
          event.target.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          if (event.target.className !== "dropdown-item") {
            event.target.removeAttribute("style");
          } else {
            let educationCtrl = document.getElementById(event.target.id);
            educationCtrl.style.border = "1px solid #6b748e";
          }
        }
      }
      if (
        event.target.id.includes("degree_option") &&
        cntrlWithFocus !== "degree_option"
      ) {
        obj_error = document.getElementById("degree_name_error");
        let ctrlToValidate = document.getElementById("degree_name");
        if (degree.length === 0) {
          ctrlToValidate.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          if (ctrlToValidate.className !== "dropdown-item") {
            ctrlToValidate.removeAttribute("style");
          } else {
            let genderCtrl2 = document.getElementById("degree_name");
            genderCtrl2.style.border = "1px solid #6b748e";
          }
        }
      }
      if (event.target.id.includes("institution_name")) {
        obj_error = document.getElementById("institution_name_error");
        if (
          event.target.value.trim(" ").length < 3 ||
          institutionName.length < 3
        ) {
          event.target.style.color = "#e21224";
          event.target.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          event.target.style.color = "#6b748e";
          obj_error.style.display = "none";
          event.target.removeAttribute("style");
        }
      }
      if (event.target.id.includes("major_in")) {
        obj_error = document.getElementById("major_in_error");
        if (event.target.value.trim(" ").length < 3 || majorIn.length < 3) {
          event.target.style.border = "1px solid #e21224";
          event.target.style.color = "#e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          event.target.removeAttribute("style");
        }
      }
      if (event.target.id.includes("city")) {
        obj_error = document.getElementById("city_error");
        if (event.target.value.trim(" ").length < 2 || city.length < 2) {
          event.target.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          event.target.style.color = "#e21224";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          event.target.removeAttribute("style");
          event.target.style.color = "#555";
        }
      }
      if (event.target.id.includes("state_id") && showState === true) {
        obj_error = document.getElementById("state_id_error");
        let obj_err = document.getElementById("state_id_opt");

        if (event.target.innerText.trim("").length === 0) {
          obj_err.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          if (event.target.className !== "dropdown-item") {
            obj_err.removeAttribute("style");
          } else {
            let educationCtrl = document.getElementById(event.target.id);
            educationCtrl.style.border = "1px solid #6b748e";
          }
        }
      }
      if (
        showState === true &&
        event.target.id.includes("state_option") &&
        cntrlWithFocus !== "state_option"
      ) {
        obj_error = document.getElementById("state_id_error");
        let ctrlToValidate = document.getElementById("state_id_opt");
        if (state.length === 0) {
          ctrlToValidate.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          if (ctrlToValidate.className !== "dropdown-item") {
            ctrlToValidate.removeAttribute("style");
          } else {
            let genderCtrl2 = document.getElementById("state_id_opt");
            genderCtrl2.style.border = "1px solid #6b748e";
          }
        }
      }
      if (isCareerUser() && event.target.id.includes("country_id")) {
        obj_error = document.getElementById("country_id_error");

        if (event.target.innerText.trim(" ").length === 0) {
          event.target.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          obj_error.style.display = "none";
          if (event.target.className !== "dropdown-item") {
            event.target.removeAttribute("style");
          } else {
            let educationCtrl = document.getElementById(event.target.id);
            educationCtrl.style.border = "1px solid #6b748e";
          }
        }
      }
      if (event.target.id.includes("overallGpa")) {
        obj_error = document.getElementById("overallGpaError");
        let gpaVal = event.target.value.replace(/_/g, "");
        if (
          gpaVal.trim(" ").length < 3 ||
          overallGpa.trim(" ").length < 3 ||
          gpaVal > 5
        ) {
          event.target.style.color = "#e21224";
          event.target.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          event.target.style.color = "#6b748e";
          obj_error.style.display = "none";
          event.target.removeAttribute("style");
        }
      }
      if (event.target.id.includes("majorGpa")) {
        obj_error = document.getElementById("majorGpaError");
        let gpaVal = event.target.value.replace(/_/g, "");
        if (
          gpaVal.trim().length < 3 ||
          majorGpa.trim().length < 3 ||
          gpaVal > 5
        ) {
          event.target.style.color = "#e21224";
          event.target.style.border = "1px solid #e21224";
          obj_error.style.display = "block";
          hasError = true;
        } else {
          event.target.style.color = "#6b748e";
          obj_error.style.display = "none";
          event.target.removeAttribute("style");
        }
      }
      if (event.target.id.includes("_date")) {
        let date = new Moment();
        let start_date =
          startDate === "" ? "" : new Moment(startDate, "MM/YYYY");
        let end_date = endDate === "" ? "" : new Moment(endDate, "MM/YYYY");

        if (event.target.id === "start_date") {
          var error;
          error = document.getElementById("start_date_error");
          let currDate3 = Moment();
          let dateParts3 = event.target.value.split("/");
          let month3 = dateParts3[0];
          let year3 = dateParts3[1];
          let dateCalc3 = Moment(year3 + "-" + month3);
          let yearDiff3 = currDate3.diff(dateCalc3, "year");

          if (
            event.target.value.includes("_") ||
            event.target.value === "" ||
            !isValidDate(event.target.value) ||
            event.target.value.includes("0000")
          ) {
            event.target.style.border = "1px solid #e21224";
            error.style.display = "block";
            if (
              props.contracttypedesc ===
              CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN
            ) {
              error.innerText = "Please enter enrollment Start Date";
            } else {
              error.innerText = "Please enter a Start Date";
            }
            event.target.style.color = "#e21224";
            hasError = true;
            hasError = true;
          } else if (dateCalc3 > currDate3 || start_date.isAfter(date)) {
            event.target.style.border = "1px solid #e21224";
            error.innerText = "Enrollment Start Date cannot be a future date";
            error.style.display = "block";
            event.target.style.color = "#e21224";
            hasError = true;
          } else if (yearDiff3 >= 80) {
            event.target.style.border = "1px solid #e21224";
            error.innerText =
              "Enrollment Start Date cannot be more than 80 years ago";
            error.style.display = "block";
            event.target.style.color = "#e21224";
            hasError = true;
          } else if (
            isCareerUser() &&
            end_date !== "" &&
            start_date.isAfter(end_date)
          ) {
            error.innerText =
              "Enrollment Start Date cannot be after expected Graduation Date";
            event.target.style.border = "1px solid #e21224";
            error.style.display = "block";
            event.target.style.color = "#e21224";
            hasError = true;
          } else {
            error.style.display = "none";
            event.target.removeAttribute("style");
            event.target.style.color = "#555";
            let end_date_error = document.getElementById("end_date_error");
            if (end_date_error.style.display === "block") {
              // run onblur for start_date
              let end_date_error = new Event("blur");
              Object.defineProperty(end_date_error, "target", {
                writable: false,
                value: document.getElementById("end_date"),
              });

              onBlur(end_date_error);
            }
          }
        }
        if (event.target.id === "end_date") {
          var obj_error2;
          let currDate3 = Moment();
          let dateParts3 = event.target.value.split("/");
          let month3 = dateParts3[0];
          let year3 = dateParts3[1];
          let dateCalc3 = Moment(year3 + "-" + month3);
          let pls3yrs = Moment().add(3, "years").subtract(1, "months");
          obj_error2 = document.getElementById("end_date_error");

          if (
            event.target.value.includes("_") ||
            event.target.value === "" ||
            !isValidDate(event.target.value) ||
            event.target.value.includes("0000")
          ) {
            event.target.style.border = "1px solid #e21224";
            obj_error2.style.display = "block";
            if (
              props.contracttypedesc ===
              CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN
            ) {
              obj_error2.innerText = "Please enter expected Graduation Date";
            } else {
              obj_error2.innerText = "Please enter an End Date";
            }
            event.target.style.color = "#e21224";
            hasError = true;
          } else if (
            isCareerUser() &&
            start_date !== "" &&
            start_date.isAfter(end_date)
          ) {
            obj_error2.innerText =
              "Expected Graduation Date cannot be before Enrollment Start Date";
            event.target.style.border = "1px solid #e21224";
            obj_error2.style.display = "block";
            event.target.style.color = "#e21224";
            hasError = true;
          } else if (dateCalc3 > pls3yrs) {
            event.target.style.border = "1px solid #e21224";
            obj_error2.innerText =
              "Expected Graduation Date can be a date no more than 3 years from now";
            obj_error2.style.display = "block";
            event.target.style.color = "#e21224";
            hasError = true;
          } else if (
            isCollegeUser() &&
            dateCalc3 < currDate3.subtract(1, "months")
          ) {
            event.target.style.border = "1px solid #e21224";
            obj_error2.innerText =
              "Expected Graduation Date cannot be a past date";
            obj_error2.style.display = "block";
            event.target.style.color = "#e21224";
            hasError = true;
          } else {
            obj_error2.style.display = "none";
            event.target.removeAttribute("style");
            event.target.style.color = "#6b748e";

            let start_date_error = document.getElementById("start_date_error");
            if (start_date_error.style.display === "block") {
              // run onblur for start_date
              var startdateEvent = new Event("blur");
              Object.defineProperty(startdateEvent, "target", {
                writable: false,
                value: document.getElementById("start_date"),
              });

              onBlur(startdateEvent);
            }
          }
        }
      }
    }
    if (hasError) {
      setFormHasError(true);
    } else {
      setFormHasError(false);
      hasError = false;
    }
  };

  const handleCloseBackModal = () => {
    setShowBackModal(false);
  };

  const handleBackClick = () => {
    if (formHasError === false) {
      props.isFormComplete(true);
    }
    handleCloseBackModal();
    if (props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN) {
      props.toggleForm(FORM_ID.RESIDENTIAL_HISTORY);
    } else {
      props.toggleForm(FORM_ID.BUSINESS_INFO);
    }
  };

  const handleShowBackModal = () => {
    if (formHasChanged) {
      setShowBackModal(true);
    } else {
      if (
        props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN
      ) {
        props.toggleForm(FORM_ID.RESIDENTIAL_HISTORY);
      } else {
        props.toggleForm(FORM_ID.BUSINESS_INFO);
      }
    }
  };

  const setYearId = (year) => {
    switch (year) {
      case "Junior":
        return 1;
      case "Senior":
        return 2;
      case "Graduate Program":
        return 3;
      default:
        return "";
    }
  };

  const getYearId = (yearId) => {
    switch (yearId) {
      case 1:
        return "Junior";
      case 2:
        return "Senior";
      case 3:
        return "Graduate Program";
      default:
        return "";
    }
  };

  const getCurrInd = (currInd) => {
    if (currInd) {
      return "Yes";
    } else return "No";
  };

  const setCurrInd = (currInd) => {
    if (currInd === "Yes") {
      return true;
    } else return false;
  };

  const handleShowEduReqModal = () => {
    setShowEduReqModal(true);
  };

  const handleCloseEduReqModal = () => {
    setShowEduReqModal(false);
  };

  const handleBackEduClick = () => {
    handleCloseEduReqModal();
    window.location = "/";
  };

  const isCareerUser = () => {
    return (
      props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
      props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
      props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
      props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
    );
  };

  const isCollegeUser = () => {
    return props.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN;
  };

  const storeData = (data) => {
    if (isCareerUser()) {
      data = {
        candidateid: props.candidateId,
        degree: degree,
        educationdegreeid: parseInt(degreeId),
        major: majorIn,
        institutionname: institutionName,
        startdate: startDate,
        enddate: endDate,
        city: city,
        state: state,
        country: country,
        educationlevel: highestEducation,
        educationlevelid: parseInt(highestEducationId),
      };
    }

    if (isCollegeUser()) {
      data = {
        candidateid: props.candidateId,
        city: city,
        country: "US",
        fulltimeenrolled: setCurrInd(currEnrolled),
        degreeseeking: degree,
        expectgraddate: endDate,
        institutionname: institutionName,
        major: majorIn,
        currentgpamajor: majorGpa,
        currentgpaoverall: overallGpa,
        enrolldate: startDate,
        state: state,
        currentacademicyearid: setYearId(yearEnrolled),
      };
    }
    return data;
  };

  const clearData = async () => {
    setCity("");
    setState("");
    setCountry("");
    setInstitutionName("");
    setMajorIn("");
    setStartDate("");
    setEndDate("");
    setMajorGpa("");
    setOverallGpa("");
    setYearEnrolled("");
    setDegree("");
  };

  const handleChange = (event) => {
    hasChange = true;
    props.isFormComplete(false);
    props.hasCurrentFormChanged(true);

    if (event.target.id === "education_id") {
      setHighestEducationId(event.target.value);
      setHighestEducation(event.target.innerText);

      if (
        event.target.innerText === "Attended College - No Degree" ||
        event.target.innerText ===
          "Completed secondary education (Graduated High School)"
      ) {
        setDisableMajorIn(true);
        setMajorIn("");
      } else {
        setDisableMajorIn(false);
      }
    }

    if (event.target.id === "country_id") {
      if (event.target.value === "US") {
        setShowState(true);
      } else {
        setShowState(false);
        setState("");
        let obj_error = document.getElementById("state_id_error");
        obj_error.style.display = "none";
        let ctrlToValidate = document.getElementById("state_id_opt");
        ctrlToValidate.removeAttribute("style");
      }
      setCountry(event.target.value);
    }

    if (event.target.id.includes("collegeEnrolled")) {
      var obj_error = document.getElementById("collegeEnrollError");
      setCurrEnrolled(event.target.value);

      obj_error = document.getElementById("collegeEnrollFillError");
      let currentlyMilitaryYes = document.getElementById("collegeEnrolledYes");
      currentlyMilitaryYes.classList.remove("is-invalid");
      let currentlyMilitaryNo = document.getElementById("collegeEnrolledNo");
      currentlyMilitaryNo.classList.remove("is-invalid");
      obj_error.style.display = "none";
      hasError = false;

      if (event.target.value !== "Yes") {
        handleShowEduReqModal();
        obj_error.style.display = "block";
        document.getElementById("collegeEnrollFillError").style.display =
          "none";
        hasError = true;
        if (event.target.value === "No") {
          clearData();
        }
      } else if (event.target.value === "Yes") {
        obj_error.style.display = "none";
        document.getElementById("collegeEnrollFillError").style.display =
          "none";
        hasError = false;
        setLoading(false);
      }
    }

    if (event.target.id.includes("yearEnrolled")) {
      setYearEnrolled(event.target.value);
      obj_error = document.getElementById("collegeYearError");
      let yearEnrolledJunior = document.getElementById("yearEnrolledJunior");
      yearEnrolledJunior.classList.remove("is-invalid");
      let yearEnrolledSenior = document.getElementById("yearEnrolledSenior");
      yearEnrolledSenior.classList.remove("is-invalid");
      let yearEnrolledGrad = document.getElementById("yearEnrolledGrad");
      yearEnrolledGrad.classList.remove("is-invalid");
      obj_error.style.display = "none";
      hasError = false;
    }

    if (event.target.id === "overallGpa") {
      setOverallGpa(event.target.value.replace(/_/g, ""));
    }

    if (event.target.id === "majorGpa") {
      setMajorGpa(event.target.value.replace(/_/g, ""));
    }

    if (event.target.id === "state_id_opt") {
      setState(event.target.value);
    }
    if (event.target.id === "city") {
      setCity(event.target.value);
    }
    if (event.target.id === "major_in") {
      setMajorIn(event.target.value);
    }
    if (event.target.id === "institution_name") {
      setInstitutionName(event.target.value);
    }
    if (event.target.id === "degree_name") {
      setDegreeId(event.target.value);
      setDegree(event.target.innerText);
    }
    if (event.target.id === "start_date") {
      setStartDate(event.target.value);
    }
    if (event.target.id === "end_date") {
      setEndDate(event.target.value);
    }
    setFormHasChanged(true);
  };

  const onKeyDown = (event) => {
    if (
      event.target.id !== "education_id" &&
      event.target.id !== "degree_name" &&
      event.target.id !== "state_id_opt" &&
      event.target.id !== "country_id"
    ) {
      props.isFormComplete(false);
    }
  };

  const onFocusState = () => {
    setCntrlWithFocus("");
    let inputs = document.querySelectorAll("button, a");
    try {
      for (let i = 0; i < inputs.length; ++i) {
        inputs[i].addEventListener("focusout", (event) => {
          if (event.relatedTarget == null) {
            setCntrlWithFocus("");
          } else {
            setCntrlWithFocus(event.relatedTarget.id);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNext = async () => {
    if (isCareerUser()) {
      if (institutionName === "") {
        hasError = true;
      }
      let highestEducation = new Event("blur");
      Object.defineProperty(highestEducation, "target", {
        writable: false,
        value: document.getElementById("education_id"),
      });
      await onBlur(highestEducation);
    }

    if (isCollegeUser()) {
      if (currEnrolled === "No") {
        handleShowEduReqModal();
        return;
      }
      if (currEnrolled !== "Yes") {
        var obj_error = document.getElementById("collegeEnrollFillError");
        let currentlyMilitaryYes =
          document.getElementById("collegeEnrolledYes");
        currentlyMilitaryYes.classList.add("is-invalid");
        let currentlyMilitaryNo = document.getElementById("collegeEnrolledNo");
        currentlyMilitaryNo.classList.add("is-invalid");
        obj_error.style.display = "block";
        let label_yes = document.getElementById("label_collegeEnrolledYes");
        label_yes.style.color = "#555";
        let label_no = document.getElementById("label_collegeEnrolledNo");
        label_no.style.color = "#555";
        hasError = true;

        return;
      }
      if (currEnrolled === "Yes") {
        obj_error = document.getElementById("collegeYearError");
        if (yearEnrolled === "") {
          let yearEnrolledJunior =
            document.getElementById("yearEnrolledJunior");
          yearEnrolledJunior.classList.add("is-invalid");
          let yearEnrolledSenior =
            document.getElementById("yearEnrolledSenior");
          yearEnrolledSenior.classList.add("is-invalid");
          let yearEnrolledGrad = document.getElementById("yearEnrolledGrad");
          yearEnrolledGrad.classList.add("is-invalid");
          obj_error.style.display = "block";
          let label_yearEnrolledJunior = document.getElementById(
            "label_yearEnrolledJunior"
          );
          label_yearEnrolledJunior.style.color = "#555";
          let label_yearEnrolledSenior = document.getElementById(
            "label_yearEnrolledSenior"
          );
          label_yearEnrolledSenior.style.color = "#555";
          let label_yearEnrolledGrad = document.getElementById(
            "label_yearEnrolledGrad"
          );
          label_yearEnrolledGrad.style.color = "#555";
          hasError = true;
        } else if (yearEnrolled !== "") {
          obj_error.style.display = "none";
          hasError = false;
        }
        let overallGpa = new Event("blur");
        Object.defineProperty(overallGpa, "target", {
          writable: false,
          value: document.getElementById("overallGpa"),
        });
        await onBlur(overallGpa);

        let majorGpa = new Event("blur");
        Object.defineProperty(majorGpa, "target", {
          writable: false,
          value: document.getElementById("majorGpa"),
        });
        await onBlur(majorGpa);
      }
    }
    if (isCareerUser()) {
      let countryElem = new Event("blur");
      Object.defineProperty(countryElem, "target", {
        writable: false,
        value: document.getElementById("country_id"),
      });
      await onBlur(countryElem);
    }
    if (isCareerUser() || (isCollegeUser() && currEnrolled === "Yes")) {
      let institutionName1 = new Event("blur");
      Object.defineProperty(institutionName1, "target", {
        writable: false,
        value: document.getElementById("institution_name"),
      });
      await onBlur(institutionName1);
      let cityElem = new Event("blur");
      Object.defineProperty(cityElem, "target", {
        writable: false,
        value: document.getElementById("city"),
      });
      await onBlur(cityElem);
      if (showState === true) {
        let stateElem = new Event("blur");
        Object.defineProperty(stateElem, "target", {
          writable: false,
          value: document.getElementById("state_id_opt"),
        });
        await onBlur(stateElem);
      }

      let startDateElem = new Event("blur");
      Object.defineProperty(startDateElem, "target", {
        writable: false,
        value: document.getElementById("start_date"),
      });
      await onBlur(startDateElem);

      let endDateElem = new Event("blur");
      Object.defineProperty(endDateElem, "target", {
        writable: false,
        value: document.getElementById("end_date"),
      });
      await onBlur(endDateElem);
      let degreeReceived = new Event("blur");
      Object.defineProperty(degreeReceived, "target", {
        writable: false,
        value: document.getElementById("degree_name"),
      });
      await onBlur(degreeReceived);
    }
    if (
      (isCareerUser() && disableMajorIn === false) ||
      (isCollegeUser() && currEnrolled === "Yes")
    ) {
      let majorIn = new Event("blur");
      Object.defineProperty(majorIn, "target", {
        writable: false,
        value: document.getElementById("major_in"),
      });
      await onBlur(majorIn);
    }
    if (formHasError === false && formHasChanged === true && !hasError) {
      setLoading(true);
      let data = {};
      data = storeData();

      if (isCareerUser()) {
        await updateCandidateEducation(props.subId, data);
        props.saveEduInfo([data]);
      } else if (isCollegeUser()) {
        await updateInternEducation(props.subId, data);
        props.saveInternEduInfo([data]);
      }

      props.hasCurrentFormChanged(false);

      setFormHasChanged(false);
      props.isFormComplete(true);
      hasChange = false;
      setLoading(false);
    }
    if (!hasChange && !hasError) {
      props.toggleForm(FORM_ID.PROFESSIONAL_DESIGNATION);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let data = {};
      let degrees = props.eduDegrees;
      setDEGREES(degrees);
      let educationlevel = props.eduLevels;
      setEDUCATIONLEVEL(educationlevel);

      setFormHasError(false);

      if (isCareerUser()) {
        data = props.eduData;

        if (data.length > 0) {
          setCity(data[0].city || "");
          setState(data[0].state || "");
          setCountry(data[0].country || "");
          setInstitutionName(data[0].institutionname || "");
          setMajorIn(data[0].major || "");
          setStartDate(data[0].startdate || "");
          setEndDate(data[0].enddate || "");

          let degObj = degrees.find(
            (o) => o.educationdegreeid === data[0].educationdegreeid
          );

          setDegree(degObj.educationdegreedesc || "");
          setDegreeId(degObj.educationdegreeid || "");

          let eduObj = educationlevel.find(
            (o) => o.educationlevelid === data[0].educationlevelid
          );

          setHighestEducation(eduObj.educationleveldesc || "");
          setHighestEducationId(eduObj.educationlevelid || "");
          if (eduObj.educationlevelid === 1 || eduObj.educationlevelid === 2) {
            if (
              highestEducationId === 1 ||
              highestEducationId === 2 ||
              highestEducationId === ""
            ) {
              setDisableMajorIn(true);
              setMajorIn("");
            } else {
              setDisableMajorIn(false);
            }
          }
        }
      } else if (isCollegeUser()) {
        data = props.internEduData;
        if (data.length > 0 && Object.keys(data[0])[0].includes("ieh_")) {
          setCity(data[0].ieh_city || "");
          setState(data[0].ieh_state || "");
          setCountry(data[0].ieh_country || "");
          setInstitutionName(data[0].ieh_institutionname || "");
          setMajorIn(data[0].ieh_major || "");
          setStartDate(data[0].ieh_enrolldate || "");
          setEndDate(data[0].ieh_expectgraddate || "");
          setMajorGpa(data[0].ieh_currentgpamajor || "");
          setOverallGpa(data[0].ieh_currentgpaoverall || "");
          setCurrEnrolled(getCurrInd(data[0].ieh_fulltimeenrolled || ""));
          setYearEnrolled(getYearId(data[0].ieh_currentacademicyearid || ""));
          setDisableMajorIn(false);
          setDegree(data[0].ieh_degreeseeking);
        } else if (data.length > 0) {
          setCity(data[0].city || "");
          setState(data[0].state || "");
          setCountry(data[0].country || "");
          setInstitutionName(data[0].institutionname || "");
          setMajorIn(data[0].major || "");
          setStartDate(data[0].enrolldate || "");
          setEndDate(data[0].expectgraddate || "");
          setMajorGpa(data[0].currentgpamajor || "");
          setOverallGpa(data[0].currentgpaoverall || "");
          setCurrEnrolled(getCurrInd(data[0].fulltimeenrolled || ""));
          setYearEnrolled(getYearId(data[0].currentacademicyearid || ""));
          setDisableMajorIn(false);
          setDegree(data[0].degreeseeking);
        }
        setShowState(true);
      }
      return data;
    };

    fetchData();
    UtagTracking.sendPageNameView(
      "educationform",
      props.candidateId,
      props.agencynumber,
      props.contracttypeid
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (isOpen) => {
    const dropdownMenu = document.querySelector(".dropdown-menu.show");
    if (dropdownMenu) {
      dropdownMenu.scrollTop = 0;
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h1>Education</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <span className="required-field-text">* = Required Field</span>
        </div>
      </div>
      {isCollegeUser() ? (
        <>
          <div className="row" style={{ paddingTop: "15px" }}>
            <div className="col-md-12">
              <h3
                style={{
                  fontWeight: "bold",
                  color: "black",
                  letterSpacing: "1px",
                  fontSize: "20px",
                }}
              >
                Please provide your education information.
              </h3>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "40px" }}>
            <div className="col-md-12">
              <strong>Are you currently enrolled full time in college?*</strong>
            </div>
          </div>

          <div className="row">
            <div className="custom-control">
              <div className="col-12 copy-md-8" style={{ paddingTop: "10px" }}>
                <div className="custom-control custom-radio">
                  <input
                    name="radio-guide"
                    type="radio"
                    id="collegeEnrolledYes"
                    className="custom-control-input"
                    value="Yes"
                    onChange={handleChange}
                    checked={currEnrolled === "Yes"}
                    required
                  />
                  <label
                    htmlFor="collegeEnrolledYes"
                    className="custom-control-label"
                    id="label_collegeEnrolledYes"
                  >
                    Yes
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="radio-guide"
                    type="radio"
                    id="collegeEnrolledNo"
                    className="custom-control-input"
                    value="No"
                    onChange={handleChange}
                    checked={currEnrolled === "No"}
                    required
                  />
                  <label
                    htmlFor="collegeEnrolledNo"
                    className="custom-control-label"
                    id="label_collegeEnrolledNo"
                  >
                    No
                  </label>
                </div>
                <div id="collegeEnrollError" className="invalid-feedback">
                  Education Requirements Not Met
                </div>
                <div id="collegeEnrollFillError" className="invalid-feedback">
                  Please choose a response
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-md-12" style={{ paddingTop: "15px" }}>
            <h3>Provide your education information.</h3>
          </div>
        </div>
      )}

      {isCollegeUser() && currEnrolled === "Yes" && (
        <>
          <div className="row">
            <div className="col">
              <strong>
                What academic year are you currently enrolled in?*
              </strong>
            </div>
          </div>
          <div className="row">
            <div className="custom-control">
              <div className="col-12 copy-md-8" style={{ paddingTop: "10px" }}>
                <div className="custom-control custom-radio">
                  <input
                    name="radio-guide-year"
                    type="radio"
                    id="yearEnrolledJunior"
                    className="custom-control-input"
                    value="Junior"
                    onChange={handleChange}
                    checked={yearEnrolled === "Junior"}
                    required
                  />
                  <label
                    htmlFor="yearEnrolledJunior"
                    className="custom-control-label"
                    id="label_yearEnrolledJunior"
                  >
                    Junior
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="radio-guide-year"
                    type="radio"
                    id="yearEnrolledSenior"
                    className="custom-control-input"
                    value="Senior"
                    onChange={handleChange}
                    checked={yearEnrolled === "Senior"}
                    required
                  />
                  <label
                    htmlFor="yearEnrolledSenior"
                    className="custom-control-label"
                    id="label_yearEnrolledSenior"
                  >
                    Senior
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="radio-guide-year"
                    type="radio"
                    id="yearEnrolledGrad"
                    className="custom-control-input"
                    value="Graduate Program"
                    onChange={handleChange}
                    checked={yearEnrolled === "Graduate Program"}
                    required
                  />
                  <label
                    htmlFor="yearEnrolledGrad"
                    className="custom-control-label"
                    id="label_yearEnrolledGrad"
                  >
                    Graduate Program
                  </label>
                </div>
                <div id="collegeYearError" className="invalid-feedback">
                  Please choose a response
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isCareerUser() && (
        <>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col">
              <label htmlFor="state_id_opt">
                <strong>
                  What was the highest level of education you received?*
                </strong>
              </label>

              <DropdownButton
                id="education_id"
                type="default"
                variant="secondary"
                title={highestEducation}
                selected={false}
                autoComplete="new-address"
                onSelect={(key, event) => {
                  event.target.id = "education_id";
                  event.target.value = key;
                  handleChange(event);
                }}
                onBlur={onBlur}
                onClick={clickFunc}
                onFocus={onFocusState}
                onMouseDown={onKeyDown}
                // disabled={props.busAddrCheck === true}
                required
              >
                {EDUCATIONLEVEL.map((edu, index) => {
                  return (
                    <div key={index}>
                      <Dropdown.Item
                        eventKey={edu.educationlevelid}
                        key={edu.educationlevelid}
                        id="education_option"
                      >
                        {edu.educationleveldesc}
                      </Dropdown.Item>
                    </div>
                  );
                })}
              </DropdownButton>
              <div id={"education_error"} className="invalid-feedback">
                Please choose the highest level of education you received
              </div>
            </div>
          </div>
        </>
      )}
      {((isCollegeUser() && currEnrolled === "Yes") || isCareerUser()) && (
        <>
          {isCareerUser() && (
            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col">
                <label htmlFor="institution_name">
                  <strong>Which Degree did you receive?*</strong>
                </label>
                <DropdownButton
                  id="degree_name"
                  type="default"
                  variant="secondary"
                  title={degree}
                  selected={false}
                  autoComplete="new-password"
                  onSelect={(key, event) => {
                    event.target.id = "degree_name";
                    event.target.value = key;
                    handleChange(event);
                  }}
                  onBlur={onBlur}
                  onClick={clickFunc}
                  onMouseDown={onKeyDown}
                  onFocus={onFocusState}
                  required
                >
                  {DEGREES.map((degree, index) => {
                    return (
                      <div key={index}>
                        <Dropdown.Item
                          eventKey={degree.educationdegreeid}
                          key={degree.educationdegreeid}
                          id="degree_option"
                        >
                          {degree.educationdegreedesc}
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
                <div id={"degree_name_error"} className="invalid-feedback">
                  Please choose which Degree you received
                </div>
              </div>
            </div>
          )}
          {disableMajorIn === false && isCareerUser() && (
            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col">
                <label htmlFor="institution_name">
                  <strong>
                    What did you Major in
                    {disableMajorIn === false ? "?*" : "?"}
                  </strong>
                </label>

                <input
                  type="text"
                  id="major_in"
                  className="form-control form-control form-control-15"
                  maxLength={100}
                  minLength={3}
                  value={majorIn}
                  placeholder="ex: Political Science"
                  onBlur={onBlur}
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  disabled={disableMajorIn}
                  autoComplete="off"
                ></input>
                <div id="major_in_error" className="invalid-feedback">
                  Please enter your Major
                </div>
              </div>
            </div>
          )}
          {isCareerUser() && (
            <>
              <div style={{ paddingTop: "20px" }}>
                <strong>
                  Complete the following regarding your highest level of
                  education:
                </strong>
              </div>
              <div className="row" style={{ paddingTop: "20px" }}>
                <div className="col">
                  <label htmlFor="institution_name">
                    <strong>Institution Name*</strong>
                  </label>

                  <input
                    type="text"
                    id="institution_name"
                    className="form-control form-control form-control-15"
                    maxLength={100}
                    minLength={3}
                    value={institutionName}
                    onBlur={onBlur}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                  ></input>
                  <div id="institution_name_error" className="invalid-feedback">
                    Please enter the Institution Name
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <strong>What dates did you attend?*</strong>
              </div>

              <div
                className="row"
                style={{
                  paddingTop: "15px",
                }}
              >
                <div className="col">
                  <label htmlFor="start_date">
                    <strong> Start Date* </strong>
                  </label>

                  <InputMask
                    id="start_date"
                    mask="99/9999"
                    maskChar="_"
                    className="form-control"
                    placeholder="MM/YYYY"
                    value={startDate}
                    onBlur={onBlur}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    required
                    autoComplete="off"
                  />

                  <div id="start_date_error" className="invalid-feedback">
                    Please enter a Start Date
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="institution_name">
                    <strong> End Date* </strong>
                  </label>

                  <InputMask
                    id="end_date"
                    mask="99/9999"
                    maskChar="_"
                    className="form-control"
                    placeholder="MM/YYYY"
                    value={endDate}
                    onBlur={onBlur}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    required
                    autoComplete="off"
                  />

                  <div id={"end_date_error"} className="invalid-feedback">
                    Please enter an End Date
                  </div>
                </div>
              </div>
            </>
          )}
          {isCollegeUser() && (
            <div className="row">
              <div className="col" style={{ maxWidth: "30%" }}>
                <label htmlFor="institution_name">
                  <strong>College Name*</strong>
                </label>
                <input
                  type="text"
                  id="institution_name"
                  className="form-control form-control form-control-15"
                  maxLength={100}
                  minLength={3}
                  value={institutionName}
                  onBlur={onBlur}
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  autoComplete="off"
                ></input>
                <div id="institution_name_error" className="invalid-feedback">
                  Please enter College Name
                </div>
              </div>

              <div className="col">
                <label htmlFor="city" aria-label="City">
                  <strong>City*</strong>
                </label>
                <input
                  type="text"
                  id="city"
                  className="form-control form-control form-control-15"
                  maxLength={100}
                  minLength={2}
                  value={city}
                  onBlur={onBlur}
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  autoComplete="off"
                  aria-label="City"
                />
                <div id="city_error" className="invalid-feedback">
                  Please enter a City
                </div>
              </div>
            </div>
          )}
          {isCareerUser() && (
            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col">
                <label htmlFor="city" aria-label="City">
                  <strong>City*</strong>
                </label>
                <input
                  type="text"
                  id="city"
                  className="form-control form-control form-control-15"
                  maxLength={100}
                  minLength={2}
                  value={city}
                  onBlur={onBlur}
                  onChange={handleChange}
                  aria-label="City"
                  onKeyDown={onKeyDown}
                  autoComplete="off"
                />
                <div id="city_error" className="invalid-feedback">
                  Please enter a City
                </div>
              </div>
            </div>
          )}
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col">
              <label id="stateprovince">
                <strong>
                  State/Province{country === "US" || isCollegeUser() ? "*" : ""}
                </strong>
              </label>

              <DropdownButton
                id="state_id_opt"
                type="default"
                variant="secondary"
                title={state}
                selected={false}
                autoComplete="new-address"
                onSelect={(key, event) => {
                  event.target.id = "state_id_opt";
                  event.target.value = key;
                  handleChange(event);
                }}
                onBlur={onBlur}
                onClick={clickFunc}
                onFocus={onFocusState}
                onToggle={handleToggle}
                required
              >
                {isCareerUser() && (
                  <Dropdown.Item eventKey={""} id="state_option">
                    {""}
                  </Dropdown.Item>
                )}
                <Dropdown.Item eventKey={"PR"} id="state_option">
                  PR - Puerto Rico
                </Dropdown.Item>
                <Dropdown.Item eventKey={"AL"} id="state_option">
                  AL - Alabama
                </Dropdown.Item>
                <Dropdown.Item eventKey={"AK"} id="state_option">
                  AK - Alaska
                </Dropdown.Item>
                <Dropdown.Item eventKey={"AZ"} id="state_option">
                  AZ - Arizona
                </Dropdown.Item>
                <Dropdown.Item eventKey={"AR"} id="state_option">
                  AR - Arkansas
                </Dropdown.Item>
                <Dropdown.Item eventKey={"CA"} id="state_option">
                  CA - California
                </Dropdown.Item>
                <Dropdown.Item eventKey={"CO"} id="state_option">
                  CO - Colorodo
                </Dropdown.Item>
                <Dropdown.Item eventKey={"CT"} id="state_option">
                  CT - Connecticut
                </Dropdown.Item>
                <Dropdown.Item eventKey={"DE"} id="state_option">
                  DE - Delaware
                </Dropdown.Item>
                <Dropdown.Item eventKey={"DC"} id="state_option">
                  DC - District of Columbia
                </Dropdown.Item>
                <Dropdown.Item eventKey={"FL"} id="state_option">
                  FL - Florida
                </Dropdown.Item>
                <Dropdown.Item eventKey={"GA"} id="state_option">
                  GA - Georgia
                </Dropdown.Item>
                <Dropdown.Item eventKey={"HI"} id="state_option">
                  HI - Hawaii
                </Dropdown.Item>
                <Dropdown.Item eventKey={"ID"} id="state_option">
                  ID - Idaho
                </Dropdown.Item>
                <Dropdown.Item eventKey={"IL"} id="state_option">
                  IL - Illinois
                </Dropdown.Item>
                <Dropdown.Item eventKey={"IN"} id="state_option">
                  IN - Indiana
                </Dropdown.Item>
                <Dropdown.Item eventKey={"IA"} id="state_option">
                  IA - Iowa
                </Dropdown.Item>
                <Dropdown.Item eventKey={"KS"} id="state_option">
                  KS - Kansas
                </Dropdown.Item>
                <Dropdown.Item eventKey={"KY"} id="state_option">
                  KY - Kentucky
                </Dropdown.Item>
                <Dropdown.Item eventKey={"LA"} id="state_option">
                  LA - Louisiana
                </Dropdown.Item>
                <Dropdown.Item eventKey={"ME"} id="state_option">
                  ME - Maine
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MD"} id="state_option">
                  MD - Maryland
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MA"} id="state_option">
                  MA - Massachusetts
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MI"} id="state_option">
                  MI - Michigan
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MN"} id="state_option">
                  MN - Minnesota
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MS"} id="state_option">
                  MS - Mississippi
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MO"} id="state_option">
                  MO - Missouri
                </Dropdown.Item>
                <Dropdown.Item eventKey={"MT"} id="state_option">
                  MT - Montana
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NE"} id="state_option">
                  NE - Nebraska
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NV"} id="state_option">
                  NV - Nevada
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NH"} id="state_option">
                  NH - New Hampshire
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NJ"} id="state_option">
                  NJ - New Jersey
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NM"} id="state_option">
                  NM - New Mexico
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NY"} id="state_option">
                  NY - New York
                </Dropdown.Item>
                <Dropdown.Item eventKey={"NC"} id="state_option">
                  NC - North Carolina
                </Dropdown.Item>
                <Dropdown.Item eventKey={"ND"} id="state_option">
                  ND - North Dakota
                </Dropdown.Item>
                <Dropdown.Item eventKey={"OH"} id="state_option">
                  OH - Ohio
                </Dropdown.Item>
                <Dropdown.Item eventKey={"OK"} id="state_option">
                  OK - Oklahoma
                </Dropdown.Item>
                <Dropdown.Item eventKey={"OR"} id="state_option">
                  OR - Oregon
                </Dropdown.Item>
                <Dropdown.Item eventKey={"PA"} id="state_option">
                  PA - Pennsylvania
                </Dropdown.Item>
                <Dropdown.Item eventKey={"RI"} id="state_option">
                  RI - Rhode Island
                </Dropdown.Item>
                <Dropdown.Item eventKey={"SC"} id="state_option">
                  SC - South Carolina
                </Dropdown.Item>
                <Dropdown.Item eventKey={"SD"} id="state_option">
                  SD - South Dakota
                </Dropdown.Item>
                <Dropdown.Item eventKey={"TN"} id="state_option">
                  TN - Tennessee
                </Dropdown.Item>
                <Dropdown.Item eventKey={"TX"} id="state_option">
                  TX - Texas
                </Dropdown.Item>
                <Dropdown.Item eventKey={"UT"} id="state_option">
                  UT - Utah
                </Dropdown.Item>
                <Dropdown.Item eventKey={"VT"} id="state_option">
                  VT - Vermont
                </Dropdown.Item>
                <Dropdown.Item eventKey={"VA"} id="state_option">
                  VA - Virginia
                </Dropdown.Item>
                <Dropdown.Item eventKey={"WA"} id="state_option">
                  WA - Washington
                </Dropdown.Item>
                <Dropdown.Item eventKey={"WV"} id="state_option">
                  WV - West Virginia
                </Dropdown.Item>
                <Dropdown.Item eventKey={"WI"} id="state_option">
                  WI - Wisconsin
                </Dropdown.Item>
                <Dropdown.Item eventKey={"WY"} id="state_option">
                  WY - Wyoming
                </Dropdown.Item>
              </DropdownButton>
              <div id={"state_id_error"} className="invalid-feedback">
                Please choose State/Province
              </div>
            </div>
          </div>
          {isCareerUser() && (
            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col">
                <label htmlFor="start_date">
                  <strong>Country*</strong>
                </label>
                <DropdownButton
                  id={"country_id"}
                  type="default"
                  variant="secondary"
                  title={country}
                  selected={false}
                  autoComplete="new-address"
                  onSelect={(key, event) => {
                    event.target.id = "country_id";
                    event.target.value = key;
                    handleChange(event);
                  }}
                  onBlur={onBlur}
                  onClick={clickFunc}
                  onFocus={onFocusState}
                  required
                >
                  {COUNTRIES.map((country, index) => {
                    return (
                      <div key={index}>
                        <Dropdown.Item
                          eventKey={country.code}
                          id="country_option"
                        >
                          {country.code + " - " + country.name}
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </DropdownButton>
                <div id={"country_id_error"} className="invalid-feedback">
                  Please choose Country
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {isCollegeUser() && currEnrolled === "Yes" && (
        <>
          <div
            className="row"
            style={{
              paddingTop: "15px",
            }}
          >
            <div className="col">
              <label htmlFor="start_date">
                <strong> What date did you enroll?* </strong>
              </label>

              <InputMask
                id="start_date"
                mask="99/9999"
                maskChar="_"
                className="form-control"
                placeholder="MM/YYYY"
                value={startDate}
                onBlur={onBlur}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                required
                autoComplete="off"
              />

              <div id="start_date_error" className="invalid-feedback">
                Please enter enrollment Start Date
              </div>
            </div>
            <div className="col">
              <label htmlFor="end_date">
                <strong> When do you expect to graduate?* </strong>
              </label>

              <InputMask
                id="end_date"
                mask="99/9999"
                maskChar="_"
                className="form-control"
                placeholder="MM/YYYY"
                value={endDate}
                onBlur={onBlur}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                required
                autoComplete="off"
              />

              <div id={"end_date_error"} className="invalid-feedback">
                Please enter expected Graduation Date
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col">
              <label htmlFor="degree_name">
                <strong>Which Degree are you seeking?*</strong>
              </label>
              <DropdownButton
                id="degree_name"
                type="default"
                variant="secondary"
                title={degree}
                selected={false}
                autoComplete="new-password"
                onSelect={(key, event) => {
                  event.target.id = "degree_name";
                  event.target.value = key;
                  handleChange(event);
                }}
                onBlur={onBlur}
                onClick={clickFunc}
                onMouseDown={onKeyDown}
                onFocus={onFocusState}
                required
              >
                {DEGREES.map((degree, index) => {
                  return (
                    <div key={index}>
                      <Dropdown.Item
                        eventKey={degree.educationdegreeid}
                        key={degree.educationdegreeid}
                        id="degree_option"
                      >
                        {degree.educationdegreedesc}
                      </Dropdown.Item>
                    </div>
                  );
                })}
              </DropdownButton>
              <div id={"degree_name_error"} className="invalid-feedback">
                Please choose which Degree you are seeking
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col">
              <label htmlFor="major_in">
                <strong>What is your Major?*</strong>
              </label>
              <input
                type="text"
                id="major_in"
                className="form-control form-control form-control-15"
                maxLength={100}
                minLength={3}
                value={majorIn}
                placeholder="ex: Political Science"
                onBlur={onBlur}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                autoComplete="off"
              ></input>
              <div id="major_in_error" className="invalid-feedback">
                Please enter your Major
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              paddingTop: "15px",
            }}
          >
            <div className="col">
              <label htmlFor="overallGpa">
                <strong> Current GPA Overall* </strong>
              </label>
              <InputMask
                id="overallGpa"
                mask="9.99"
                maskChar={overallGpa.length < 3 ? "_" : ""}
                className="form-control"
                value={overallGpa}
                onBlur={onBlur}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                required
                autoComplete="off"
              />

              <div id="overallGpaError" className="invalid-feedback">
                Please enter Current GPA Overall
              </div>
            </div>
            <div className="col">
              <label htmlFor="majorGpa">
                <strong> Current GPA in Major* </strong>
              </label>
              <InputMask
                id="majorGpa"
                mask="9.99"
                maskChar={majorGpa.length < 3 ? "_" : ""}
                className="form-control"
                value={majorGpa}
                onBlur={onBlur}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                required
                autoComplete="off"
              />

              <div id="majorGpaError" className="invalid-feedback">
                Please enter Current GPA in your Major
              </div>
            </div>
          </div>
        </>
      )}

      <NavigationButtons
        onBack={handleShowBackModal}
        onNext={handleNext}
        isLoading={loading}
        nxtbtnid={"nxtedubtn"}
        bckbtnid={"edubckbtn"}
      />

      <BackModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
      <EducationRequirementsModal
        isOpen={showEduReqModal}
        closeBackModal={handleCloseEduReqModal}
        backClick={handleBackEduClick}
      />
    </div>
  );
};

export default EducationForm;
