import { useState } from "react";
import { isNotNullUndefinedOrEmpty } from "../../../../common/Functions";

function ConfAppBusInfo(props) {
  const [busAddr, setBusAddr] = useState(props.busAddress);

  return (
    <div id="confidential-business-information">
      <div className="row ">
        <div className="col-7 mm-spacing--minor">
          <h3 id="conf_businessinfo_text">Business Information</h3>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-5">
            <strong id="conf_businessaddress_text">Address*</strong>
          </div>
          {props?.id !== "conf_bussinfo_crop" ? (
            <div id="business_address" className="col-7 word-break">
              {busAddr &&
                busAddr?.busresurbanization !== undefined &&
                busAddr?.busresurbanization !== null &&
                busAddr?.busresurbanization !== "" &&
                busAddr?.busstate === "PR" && (
                  <>{busAddr?.busresurbanization}, </>
                )}
              {`${busAddr?.busaddress1}, `}
              {busAddr?.busaddress2 !== "" && `${busAddr?.busaddress2}, `}
              {`${busAddr?.buscity}, `}
              {`${busAddr?.busstate} ${busAddr?.buszip}`}
            </div>
          ) : (
            <div id="business_address" className="col-7">
              {busAddr &&
                busAddr?.corpurbanization !== undefined &&
                busAddr?.corpurbanization !== null &&
                busAddr?.corpurbanization !== "" &&
                busAddr?.state === "PR" && <>{busAddr?.corpurbanization}, </>}
              {`${busAddr?.address1}, `}
              {busAddr?.address2 !== "" && `${busAddr?.address2}, `}
              {`${busAddr?.city}, `}
              {`${busAddr?.state} ${busAddr?.zipcode}`}
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-5">
            <strong>Business Phone Number*</strong>
          </div>
          <div className="col-7">
            {props?.id !== "conf_bussinfo_crop"
              ? isNotNullUndefinedOrEmpty(busAddr?.businessphone)
                ? busAddr?.businessphone?.slice(-12)
                : "-"
              : isNotNullUndefinedOrEmpty(busAddr?.phonenumber)
              ? busAddr?.phonenumber?.slice(-12)
              : "-"}
          </div>
        </div>

        <div className="row">
          <div className="col-5">
            <strong>Business Fax Number</strong>
          </div>
          <div className="col-7">
            {props?.id !== "conf_bussinfo_crop"
              ? isNotNullUndefinedOrEmpty(busAddr?.businessfax)
                ? busAddr?.businessfax?.slice(-12)
                : "-"
              : isNotNullUndefinedOrEmpty(busAddr?.faxnumber)
              ? busAddr?.faxnumber?.slice(-12)
              : "-"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfAppBusInfo;
