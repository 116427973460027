import React, { Component } from "react";
import { Button, Modal } from "react-blueprint-library";
import "../../assets/stylesheets/USPSValidationModal.scss";
import BackButtonModal from "../StartApplication/Forms/BackButtonModal";
import { FORM_ID } from "../../constants/Forms";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../constants/Contract";
class USPSValidationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_open: false,
      use_suggested_address: false,
      use_entered_address: false,
      street_address: "",
      address2: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      urbanization: "",
      usps_address: "",
      usps_address2: "",
      usps_city: "",
      usps_state: "",
      usps_zip_code: "",
      usps_country: "",
      contracttypedesc: "",
      src_id: "",
      showBackModal: false,
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.setData = this.setData.bind(this);
    this.clearRadioButtons = this.clearRadioButtons.bind(this);
    this.backModal1 = this.backModal1.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }
  handleOptionChange(x) {
    if (x.target.id === "useSuggestedAddress")
      this.setState({
        use_suggested_address: true,
        use_entered_address: false,
      });
    else if (x.target.id === "useEnteredAddress")
      this.setState({
        use_suggested_address: false,
        use_entered_address: true,
      });
  }
  async handleCloseModal() {
    //if (this.props.formHasChanged) {
    //  this.setState({ showBackModal: true });
    //} else {
    this.setState({ showBackModal: false });
    this.clearRadioButtons();
    this.props.closeModal();
    //}
  }

  handleBackClick() {
    this.setState({ showBackModal: false });
    this.clearRadioButtons();
    this.props.closeModal();
  }

  async backModal1() {
    if (
      this.state.contracttypedesc ===
      CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER
    ) {
      this.handleCloseModal();
    } else {
      this.clearRadioButtons();
      this.props.closeModal();
    }
  }

  async componentDidMount() {
    await this.setData();
  }
  async componentDidUpdate() {
    await this.setData();
  }

  async setData() {
    if (
      this.state.street_address !== this.props.street_address ||
      this.state.address2 !== this.props.address2 ||
      this.state.city !== this.props.city ||
      this.state.state !== this.props.state ||
      this.state.zip_code !== this.props.zip_code ||
      this.state.urbanization !== this.props.urbanization ||
      this.state.contracttypedesc !== this.props.contracttypedesc ||
      this.state.usps_address !== this.props.usps_address ||
      this.state.usps_country !== this.props.usps_country ||
      this.state.src_id !== this.props.src_id ||
      this.state.is_open !== this.props.isOpen
    ) {
      await this.setState({
        street_address: this.props.street_address,
        address2: this.props.address2,
        city: this.props.city,
        state: this.props.state,
        zip_code: this.props.zip_code,
        country: this.props.country,
        urbanization: this.props.urbanization,
        usps_urbanization: this.props.usps_urbanization,
        usps_address: this.props.usps_address,
        usps_address2: this.props.usps_address2,
        usps_city: this.props.usps_city,
        usps_state: this.props.usps_state,
        usps_zip_code: this.props.usps_zip_code,
        usps_country: this.props.usps_country,
        contracttypedesc: this.props.contracttypedesc,
        src_id: this.props.src_id,
        is_open: this.props.isOpen,
        use_suggested_address:
          this.props.usps_address !==
          "USPS does not recognize the address you entered.",
      });
    }
  }

  async handleConfirm() {
    if (
      this.state.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER ||
      this.state.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
      this.state.contracttypedesc === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP ||
      this.state.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.RETIRED_BROKER ||
      this.state.contracttypedesc ===
        CONTRACT_TYPE_DESCRIPTIONS.AGENT_EMERITUS ||
      this.state.src_id === "business"
    ) {
      if (this.state.use_suggested_address === true) {
        this.props.useSuggestedAddress();
      } else if (this.state.use_entered_address === true) {
        this.props.useEnteredAddress();
      }
    } else {
      let address = "";
      if (this.state.use_suggested_address === true)
        address = {
          address1: this.props.usps_address,
          address2: this.props.usps_address2,
          city: this.props.usps_city,
          state: this.props.usps_state,
          zipcode: this.props.usps_zip_code,
          urbanization: this.props.usps_urbanization,
          begindate: this.props.move_in_date,
          enddate: this.props.move_out_date,
          currentaddress: this.props.is_current_address,
          country: this.props.usps_country,
          county: this.props.county,
        };
      else if (this.state.use_entered_address === true) {
        address = {
          address1: this.props.street_address,
          address2: this.props.address2,
          city: this.props.city,
          state: this.props.state,
          zipcode: this.props.zip_code,
          urbanization: this.props.urbanization,
          begindate: this.props.move_in_date,
          enddate: this.props.move_out_date,
          currentaddress: this.props.is_current_address,
          country: this.props.country,
          county: this.props.county,
        };
      }

      if (this.props.editAddress === "") {
        await this.props.addToAddressHistory(address);
      } else {
        let editedAddress = address;
        editedAddress.residentialhistoryid =
          this.props.editAddress.residentialhistoryid;
        await this.props.editExistingAddress(editedAddress);
      }
    }
    this.clearRadioButtons();
  }

  async clearRadioButtons() {
    await this.setState({
      use_entered_address: false,
      use_suggested_address:
        this.props.usps_address !==
        "USPS does not recognize the address you entered.",
    });
  }
  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  render() {
    return (
      <address>
        <div id="modal"></div>

        <div>
          <Modal
            id="USPSValidationModal"
            show={this.state.is_open}
            className="modal-md"
            title=""
          >
            <div>
              <div className="modal-body">
                <div className="container">
                  <div className="row" style={{ marginBottom: "15px" }}>
                    <div className="col-md-11">
                      <h3
                        id="usps_header"
                        style={{ justifyContent: "left", marginBottom: "15px" }}
                      >
                        <strong>Address Verification</strong>
                      </h3>
                    </div>
                    <div className="col-md-1">
                      <span
                        className="icon-sm icon-close"
                        onClick={
                          this.props.formId === "residential_history"
                            ? this.props.modalclose
                            : this.backModal1
                        }
                        style={{ justifyContent: "right", cursor: "pointer" }}
                      ></span>
                    </div>
                  </div>
                  {this.state.contracttypedesc !==
                    CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER &&
                    this.props.id !== "business" && (
                      <div className="row">
                        <div className="col-md-12">
                          <h4 id="usps_step_two_text">Step 2 of 2</h4>
                        </div>
                      </div>
                    )}
                  {this.props.addressNotFound && (
                    <div
                      className="alert alert-danger"
                      id="usps_address_error"
                      role="alert"
                    >
                      The address could not be verified. Please double-check the
                      address before submitting. If the address is correct,
                      please select "Address Entered".
                    </div>
                  )}
                  <div className="row" style={{ marginBottom: "20px" }}>
                    <div className="col-md-12" id="usps_suggest_text">
                      We want to ensure the address is accurate and complete per
                      USPS address standards. Using the USPS suggested address
                      will speed up the application process.
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "10px" }}>
                    {!this.props.addressNotFound && (
                      <div
                        id="USPSAddress"
                        className="col-md-6"
                        style={{ display: "flex" }}
                      >
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="useSuggestedAddress"
                            className="custom-control-input"
                            checked={this.state.use_suggested_address}
                            onChange={this.handleOptionChange}
                            disabled={this.props.addressNotFound}
                          />
                          <label
                            htmlFor="useSuggestedAddress"
                            className="custom-control-label"
                            id="useSuggestedAddress_label"
                          >
                            <div>
                              <h4 style={{ marginBottom: "5px" }}>
                                <strong id="usps_suggested_address_text">
                                  USPS Suggested Address
                                </strong>
                                <span
                                  id="usps_preferred_text"
                                  aria-label="aria_usps_suggested_address_uspsvalid"
                                  className="badge badge-pill badge-secondary"
                                  style={{
                                    backgroundColor: "#004db2",
                                    fontWeight: "500",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Preferred
                                </span>
                              </h4>
                              <div
                                id="usps_suggested_address"
                                className={
                                  this.props.addressNotFound === true
                                    ? "modal-address-not-found"
                                    : "modal-address"
                                }
                              >
                                {this.state.state === "PR" &&
                                  !this.state.usps_address
                                    .toUpperCase()
                                    .includes("URB") && (
                                    <>{this.state.usps_address}</>
                                  )}
                                {this.state.state !== "PR" && (
                                  <>{this.state.usps_address}</>
                                )}
                                {this.state.usps_address2 !== undefined && (
                                  <>{this.state.usps_address2} </>
                                )}
                                <br />
                                {this.props.addressNotFound !== true && (
                                  <>
                                    {this.state.usps_city},{" "}
                                    {this.state.usps_state}{" "}
                                    {this.state.usps_zip_code}
                                    {this.state.contracttypedesc !==
                                      CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER && (
                                      <>, {this.state.usps_country}</>
                                    )}
                                    {this.state.state === "PR" &&
                                      this.state.usps_address
                                        .toUpperCase()
                                        .includes("URB") && (
                                        <>
                                          <br />
                                          {this.state.usps_address}
                                        </>
                                      )}
                                  </>
                                )}
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    )}

                    <div
                      id="EnteredAddress"
                      className="col-md-6"
                      style={{ display: "flex" }}
                    >
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="useEnteredAddress"
                          className="custom-control-input"
                          checked={this.state.use_entered_address}
                          onChange={this.handleOptionChange}
                        />
                        <label
                          htmlFor="useEnteredAddress"
                          className="custom-control-label"
                          id="useEnteredAddress_label"
                        >
                          <div>
                            <h4
                              id="usps_entered_address_text"
                              style={{ marginBottom: "5px" }}
                            >
                              <strong>Address Entered</strong>
                            </h4>
                            <div
                              id="usps_entered_address"
                              aria-label="aria_usps_entered_address_uspsvalid"
                              style={{
                                marginBottom: "10px",
                                wordBreak: "break-word",
                                color: "#696969",
                                width: this.props.addressNotFound
                                  ? "200%"
                                  : "auto",
                              }}
                            >
                              {this.state.street_address}
                              {this.state.address2 !== "" && (
                                <>, {this.state.address2}</>
                              )}
                              <br />
                              {this.state.city}, {this.state.state}{" "}
                              {this.state.zip_code}
                              {this.state.contracttypedesc !==
                                CONTRACT_TYPE_DESCRIPTIONS.INDIVIDUAL_BROKER && (
                                <>, {this.state.country}</>
                              )}
                              {this.state.state === "PR" && (
                                <>
                                  <br />
                                  {this.state.urbanization}
                                </>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div>
                  <Button
                    variant="link"
                    id="BackBtn"
                    label="Back"
                    onClick={this.backModal1}
                  ></Button>
                  <Button
                    id="ConfirmBtn"
                    label="Confirm"
                    onClick={this.handleConfirm}
                    disabled={
                      (this.state.use_suggested_address === false &&
                        this.state.use_entered_address === false) ||
                      this.props.usps_modal_is_loading
                    }
                  ></Button>
                  {this.props.usps_modal_is_loading === true && (
                    <div
                      style={{
                        margin: "5px 20px",
                      }}
                    >
                      <div className="mm-dots-loading" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />
      </address>
    );
  }
}

export default USPSValidationModal;
