/* eslint-disable jsx-a11y/label-has-for */
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";

import { clickFunc } from "../../../../../../../common/Functions";
import { logoutActions } from "../../../../../../../store/actions";
import {
  DRPFullNameCheckList,
  DRPProdCheckList,
  dateInputMask,
  eventStatusDateType,
} from "../../../constants";

import BackButtonModal from "../../../../../Forms/BackButtonModal";
import CheckboxList from "../../../common/CheckboxList";
import DRPCardNoButton from "../../../common/DRPCard/DRPCardNoButton";
import DRPSummaryListingCardCheck from "../../../common/DRPSummaryListingCard";

import "./RegActionDetailsForm.scss";
import { eventStatus } from "../RegActionStatusDetails";

const specialRegActionInitiated = {
  SEC: "SEC",
  OTHER: "Other",
  DEFAULT_VALUE: "Select one",
};

const regActionInitiatedArr = [
  { key: 1, name: specialRegActionInitiated.SEC },
  { key: 2, name: "Other Federal Agency" },
  { key: 3, name: "Jurisdiction" },
  { key: 4, name: "SRO" },
  { key: 5, name: "CFTC" },
  { key: 6, name: "Foreign Financial Regulatory Authority" },
  { key: 7, name: "Federal Banking Agency" },
  { key: 8, name: "National Credit Union Administration" },
  { key: 9, name: specialRegActionInitiated.OTHER },
];

const RegActionDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  values,
  getSubmitDataValues,
  data,
  groupKey,
  headerText,
  status,
  setSkipResAndSanctDetails,
}) => {
  const dispatch = useDispatch();
  const [drpCheckList, setDRPCheckList] = useState(
    data?.drpCheckList.length ? data?.drpCheckList : []
  );

  const [regActionOther, setRegActionOther] = useState(
    data?.regActionOther || ""
  );
  const [regActionOtherError, setRegActionOtherError] = useState(false);

  const [otherProdText, setOtherProdText] = useState(data?.otherProdText || "");

  const [selectedProdValues, setSelectedProdValues] = useState(
    data?.selectedProdValues || []
  );
  const [otherProdTextError, setOtherProdTextError] = useState(false);

  const [formHasChanged, setFormHasChanged] = useState(false);

  const [otherFullNameText, setOtherFullNameText] = useState(
    data?.otherFullNameText || ""
  );

  const [selectedFullNameValues, setSelectedFullNameValues] = useState(
    data?.selectedFullNameValues || []
  );
  const [otherFullNameTextError, setOtherFullNameTextError] = useState(false);

  const [selectedRegActionInitiated, setSelectedRegActionInitiated] = useState(
    data?.selectedRegActionInitiated || ""
  );

  const [dispOfAmendedChargeError, setDispOfAmendedChargeError] =
    useState(false);

  const [dateInitiated, setDateInitiated] = useState(data?.dateInitiated || "");
  const [dateInitiatedError, setDateInitiatedError] = useState(false);
  const [dateInitiatedErrorMsg, setDateInitiatedErrorMsg] = useState("");
  const [dateInitiatedRadio, setDateInitiatedRadio] = useState(
    data?.dateInitiatedRadio || ""
  );
  const [dateInitiatedExp, setDateInitiatedExp] = useState(
    data?.dateInitiatedExp || ""
  );
  const [dateInitiatedExpError, setDateInitiatedExpError] = useState(false);

  const [docketOrCase, setDocketOrCase] = useState(data?.docketOrCase || "");
  const [docketOrCaseError, setDocketOrCaseError] = useState(false);

  const [fullNameReg, setFullNameReg] = useState(data?.fullNameReg || "");
  const [fullNameRegError, setFullNameRegError] = useState(false);

  const [emplRegAction, setEmplRegAction] = useState(data?.emplRegAction || "");
  const [emplRegActionError, setEmplRegActionError] = useState(false);

  const [regActionAllegationDes, setRegActionAllegationDes] = useState(
    data?.regActionAllegationDes || ""
  );
  const [regActionAllegationDesError, setRegActionAllegationDesError] =
    useState(false);

  const [showBackModal, setShowBackModal] = useState(false);

  const [isStartDateNextDisabled, setIsStartDateNextDisabled] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    setSkipResAndSanctDetails(status === eventStatus.PENDING);

    return () => {
      window.scrollTo(0, 0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isError =
    regActionOtherError ||
    docketOrCaseError ||
    emplRegActionError ||
    regActionAllegationDesError ||
    fullNameRegError ||
    dispOfAmendedChargeError ||
    otherProdTextError ||
    otherFullNameTextError ||
    dateInitiatedError ||
    dateInitiatedExpError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isDateFirstChargedDisabled =
    !dateInitiated.length ||
    dateInitiated.includes("_") ||
    dateInitiated === dateInputMask.EMPTY ||
    (!dateInitiated.length && !dateInitiatedRadio.length) ||
    !dateInitiated.length ||
    !dateInitiatedRadio.length ||
    (dateInitiated.length &&
      dateInitiatedRadio.length &&
      dateInitiatedRadio === eventStatusDateType.EXPLANATION &&
      !dateInitiatedExp?.length &&
      dateInitiatedExp?.length < 2);

  const isNextBtnDisabled =
    !!(
      isStartDateNextDisabled ||
      selectedProdValues?.filter((x) => typeof x === "string")?.length === 0 ||
      (selectedProdValues?.includes(specialRegActionInitiated.OTHER) &&
        !otherProdText?.length) ||
      selectedFullNameValues?.filter((x) => typeof x === "string")?.length ===
        0 ||
      (selectedFullNameValues?.includes(specialRegActionInitiated.OTHER) &&
        !otherFullNameText?.length) ||
      selectedRegActionInitiated === null ||
      !selectedRegActionInitiated.length ||
      (selectedRegActionInitiated?.length &&
        selectedRegActionInitiated !== specialRegActionInitiated.SEC &&
        !fullNameReg.length) ||
      !regActionAllegationDes.length ||
      (regActionAllegationDes?.length && regActionAllegationDes?.length < 2) ||
      !docketOrCase?.length ||
      (docketOrCase?.length && docketOrCase?.length < 2) ||
      !emplRegAction?.length ||
      (emplRegAction?.length && emplRegAction?.length < 2) ||
      !docketOrCase.length ||
      !emplRegAction.length ||
      !(drpCheckList?.length > 0) ||
      isDateFirstChargedDisabled ||
      (selectedRegActionInitiated === specialRegActionInitiated.OTHER &&
        !regActionOther.length)
    ) ||
    isError ||
    isFocusedState;

  const handleCloseBackModal = () => setShowBackModal(false);

  const onOtherProdChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOtherProdText(value);
  };

  const onBlurProdOtherHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherProdTextError(true)
      : setOtherProdTextError(false);
  };

  const onOtherFullNameChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setOtherFullNameText(value);
  };

  const onBlurFullNameOtherHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setOtherFullNameTextError(true)
      : setOtherFullNameTextError(false);
  };

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    if (values.length && values.length === 1) {
      setDRPCheckList((drp) => [...drp, ...values.map((v) => v.key)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: isUpdates }));
  }, [isUpdates, dispatch]);

  const onChangeCheckedProd = ({ target: { value, checked } }) => {
    if (value === specialRegActionInitiated.OTHER) {
      setOtherProdText("");
      setOtherProdTextError(false);
    }
    setFormHasChanged(true);
    let updatedSelectedProdValues;
    if (checked) {
      updatedSelectedProdValues = [...selectedProdValues, value];
    } else {
      updatedSelectedProdValues = selectedProdValues.filter(
        (item) => item !== value
      );
    }
    // Ensure the order matches the original list
    updatedSelectedProdValues = DRPProdCheckList.filter((item) =>
      updatedSelectedProdValues.includes(item.value)
    ).map((item) => item.value);
    setSelectedProdValues(updatedSelectedProdValues);
  };

  const onChangeCheckedFullName = ({ target: { value, checked } }) => {
    if (value === specialRegActionInitiated.OTHER) {
      setOtherFullNameText("");
      setOtherFullNameTextError(false);
    }

    setFormHasChanged(true);
    let updatedSelectedFullNameValues;
    if (checked) {
      updatedSelectedFullNameValues = [...selectedFullNameValues, value];
    } else {
      updatedSelectedFullNameValues = selectedFullNameValues.filter(
        (item) => item !== value
      );
    }
    // Ensure the order matches the original list
    updatedSelectedFullNameValues = DRPFullNameCheckList.filter((item) =>
      updatedSelectedFullNameValues.includes(item.value)
    ).map((item) => item.value);
    setSelectedFullNameValues(updatedSelectedFullNameValues);
  };

  const handleShowBackModal = () => {
    if (isUpdates) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      docketOrCase,
      emplRegAction,
      drpCheckList: [...new Set(drpCheckList)],
      regActionAllegationDes,
      selectedProdValues,
      selectedFullNameValues,
      otherProdText,
      otherFullNameText,
      selectedRegActionInitiated,
      dateInitiated,
      dateInitiatedRadio,
      dateInitiatedExp,
      fullNameReg,
      regActionOther,
    };

    setFormHasChanged(false);

    if (values?.length) {
      submitData.drpCheckList = [...new Set(drpCheckList || [])];
    }

    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    drpCheckList,
    docketOrCase,
    emplRegAction,
    nextFormId,
    headerText,
    otherProdText,
    regActionOther,
    otherFullNameText,
    selectedRegActionInitiated,
    dateInitiated,
    dateInitiatedRadio,
    dateInitiatedExp,
    fullNameReg,
    selectedProdValues,
    regActionAllegationDes,
    selectedFullNameValues,
    getSubmitDataValues,
    groupKey,
    values,
  ]);

  const dropdownOnChangeRegActionInitiatedHandler = (key) => {
    setFormHasChanged(true);
    setFullNameReg("");
    setRegActionOther("");
    setRegActionOtherError(false);
    setFullNameRegError(false);
    setSelectedRegActionInitiated(key);
    setDispOfAmendedChargeError(false);
  };

  const dropdownOnBlurRegActionInitiatedHandler = () => {
    if (!selectedRegActionInitiated) {
      setDispOfAmendedChargeError(true);
    }
  };

  const handleChange = ({ target: { checked, value } }) => {
    const list = [];

    setFormHasChanged(true);

    if (checked) {
      list.push(value);

      setDRPCheckList((drp) => [...drp, ...list]);
    }

    if (!checked) {
      setDRPCheckList((drp) => drp?.filter((item) => item !== value));
    }
  };

  const handleChangeDateFirstChargedChecked = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);
    setDateInitiatedExp("");
    setDateInitiatedExpError(false);
    setDateInitiatedRadio(newValue);
  };

  const onDateFirstChargedChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateInitiated(value);

    if (value?.length === 10 && !value.includes("_") && !dateInitiatedError) {
      const today = moment();
      const date = moment(value, "MM/DD/YYYY", true);
      const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));
      if (value !== "" && !value.includes("_")) {
        if (moment(value) > today) {
          setIsStartDateNextDisabled(true);
        } else {
          setIsStartDateNextDisabled(false);
        }
      } else {
        setIsStartDateNextDisabled(false);
      }

      if (
        date._i.length === 10 &&
        !date._i.includes("_") &&
        date.isValid() &&
        !value.includes("0000")
      ) {
        if (date > today || date < past) {
          setIsStartDateNextDisabled(true);
        } else {
          setIsStartDateNextDisabled(false);
        }
      } else {
        setIsStartDateNextDisabled(true);
      }
    }
  };

  const onDateFirstChargedExplanationChange = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDateInitiatedExp(value);
  };

  const onBlurDateFirstChargedExpHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setDateInitiatedExpError(true)
      : setDateInitiatedExpError(false);
  };

  const onDateFirstChargedBlurHandler = ({ target: { value } }) => {
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    setIsStartDateNextDisabled(false);
    setFormHasChanged(true);

    if (value !== "" && !value.includes("_")) {
      if (moment(value) > today) {
        setDateInitiatedError(true);
      } else {
        setDateInitiatedError(false);
      }
    } else {
      setDateInitiatedError(false);
    }

    if (
      date._i.length === 10 &&
      !date._i.includes("_") &&
      date.isValid() &&
      !value.includes("0000")
    ) {
      if (date > today) {
        setDateInitiatedErrorMsg("Date cannot be a future date");
        setDateInitiatedError(true);
      } else if (date < past) {
        setDateInitiatedErrorMsg("Date cannot be more than 100 years ago");
        setDateInitiatedError(true);
      } else {
        setDateInitiatedError(false);
      }
    } else {
      setDateInitiatedErrorMsg("Please enter Date Initiated");
      setDateInitiatedError(true);
    }

    setFocusedState(false);
  };

  const onChangeFullNameRegHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setFullNameReg(value);
  };
  const onBlurFullNameRegHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setFullNameRegError(true)
      : setFullNameRegError(false);
  };

  const onChangeDocketOrCaseHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDocketOrCase(value);
  };

  const onBlurDocketOrCaseHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setDocketOrCaseError(true)
      : setDocketOrCaseError(false);
  };

  const onBlurEmplRegActioHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setEmplRegActionError(true)
      : setEmplRegActionError(false);
  };

  const onChangeEmplRegActionHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setEmplRegAction(value);
  };

  const onRegActionAllegationDesChangeHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setRegActionAllegationDes(value);
  };
  const onBlurRegActionAllegationDesHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 500
      ? setRegActionAllegationDesError(true)
      : setRegActionAllegationDesError(false);
  };

  const onChangeRegActionOtherHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setRegActionOther(value);
  };
  const onBlurRegActionOtherHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setRegActionOtherError(true)
      : setRegActionOtherError(false);
  };

  return (
    <div className="reg-action-details-form-wrapper">
      <div className="crim-discl-drp-summary">
        {values?.length && values.length > 1 ? (
          <DRPCardNoButton
            header={"Check the question(s) you are responding to:"}
            isDrp={values?.length > 4}
          >
            {values.map(({ key, mainQuestion, question }, index) => (
              <DRPSummaryListingCardCheck
                key={`${index}_${key}`}
                questionKey={key}
                mainQuestion={mainQuestion}
                question={question}
                index={index}
                length={values.length}
                handleChange={handleChange}
                drpCheckList={drpCheckList}
                isCheckbox
              />
            ))}
          </DRPCardNoButton>
        ) : null}
      </div>

      <div className="row disp-of-charge-container">
        <div className="col-md-8">
          <label
            id="regInitiatedLabelId"
            className="disp-of-charge-label"
            htmlFor="regActionInitiated"
          >
            Regulatory Action initiated by: *
          </label>
          <DropdownButton
            id="regActionInitiated"
            type="default"
            variant="secondary"
            title={
              selectedRegActionInitiated
                ? selectedRegActionInitiated
                : specialRegActionInitiated.DEFAULT_VALUE
            }
            className={
              dispOfAmendedChargeError
                ? "is-invalid"
                : !selectedRegActionInitiated && "disp-of-charge"
            }
            selected={false}
            autoComplete="off"
            onSelect={dropdownOnChangeRegActionInitiatedHandler}
            onBlur={dropdownOnBlurRegActionInitiatedHandler}
            onClick={clickFunc}
          >
            {regActionInitiatedArr.map(({ name, key }) => (
              <Dropdown.Item key={key} eventKey={name} id={name}>
                {name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <div id="dispOfAmendedChargeError" className="invalid-feedback">
            Please choose Regulatory Action initiated by
          </div>
        </div>
      </div>

      {selectedRegActionInitiated === specialRegActionInitiated.OTHER ? (
        <div className="court-action-other">
          <div className="col-md-12">
            <input
              type="text"
              id="otherText"
              className={
                regActionOtherError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={regActionOther}
              onChange={onChangeRegActionOtherHandler}
              onBlur={onBlurRegActionOtherHandler}
              onFocus={() => setFocusedState(true)}
            />
            <div id="regActionOtherError" className="invalid-feedback">
              Please enter a response
            </div>
          </div>
        </div>
      ) : null}

      {selectedRegActionInitiated === specialRegActionInitiated.SEC ||
        (selectedRegActionInitiated ===
          specialRegActionInitiated.DEFAULT_VALUE ||
        !selectedRegActionInitiated ? null : (
          <div className="row full-name-of-reg-container">
            <div className="col-md-10">
              <label
                id="fullNameRegLabelId"
                className="full-name-of-reg"
                htmlFor="fullNameReg"
              >
                Full name of regulator (if other than SEC) that initiated the
                action: *
              </label>
              <input
                type="text"
                id="fullNameReg"
                className={
                  fullNameRegError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                minLength={2}
                maxLength={100}
                autoComplete="off"
                value={fullNameReg}
                onChange={onChangeFullNameRegHandler}
                onBlur={onBlurFullNameRegHandler}
                onFocus={() => setFocusedState(true)}
              />
              <div id="fullNameRegError" className="invalid-feedback">
                Please enter Full Name of Regulator
              </div>
            </div>
          </div>
        ))}

      <div style={{ marginTop: "24px" }}>
        <CheckboxList
          selectedItems={selectedFullNameValues}
          list={DRPFullNameCheckList}
          header="Sanction(s) Sought: * (Select all that apply):"
          onChangeChecked={onChangeCheckedFullName}
          onOtherChangeHandler={onOtherFullNameChangeHandler}
          onBlurOtherHandler={onBlurFullNameOtherHandler}
          onFocusHandler={() => setFocusedState(true)}
          otherTextError={otherFullNameTextError}
          otherText={otherFullNameText}
        />
      </div>

      <div className="col-md-12 event-status-date-wrapper">
        <label id="dateFirstChargedLabelId" htmlFor="dateFirstChargedId">
          <strong id="regDateInitiatedTextId">Date Initiated:*</strong>
        </label>
        <InputMask
          id="dateFirstChargedId"
          mask="99/99/9999"
          maskChar="_"
          className={`${
            dateInitiatedError ? "form-control is-invalid" : "form-control"
          } event-status-date-input`}
          placeholder="MM/DD/YYYY"
          autoComplete="off"
          required
          value={dateInitiated}
          onChange={onDateFirstChargedChangeHandler}
          onBlur={onDateFirstChargedBlurHandler}
          onFocus={() => setFocusedState(true)}
        />
        <div id="dateInitiatedError" className="invalid-feedback">
          {dateInitiatedErrorMsg?.length
            ? dateInitiatedErrorMsg
            : "Please enter an Explanation"}
        </div>
      </div>
      <div className="row event-status-date-radio-group">
        <div className="custom-control">
          <div className="col-12 copy-md-8 form-group">
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-dateInitiatedExactId"
                type="radio"
                id="dateInitiatedExactId"
                className="custom-control-input"
                value={eventStatusDateType.EXACT}
                checked={dateInitiatedRadio === eventStatusDateType.EXACT}
                onChange={handleChangeDateFirstChargedChecked}
              />
              <label
                htmlFor="dateInitiatedExactId"
                className="custom-control-label"
                id="dateInitiatedExactLabelId"
              >
                {eventStatusDateType.EXACT}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="radio-guide-dateInitiatedExplanationId"
                type="radio"
                id="dateInitiatedExplanationId"
                className="custom-control-input"
                value={eventStatusDateType.EXPLANATION}
                checked={dateInitiatedRadio === eventStatusDateType.EXPLANATION}
                onChange={handleChangeDateFirstChargedChecked}
              />
              <label
                htmlFor="dateInitiatedExplanationId"
                className="custom-control-label"
                id="dateInitiatedExplanationLabelId"
              >
                {eventStatusDateType.EXPLANATION}
              </label>
            </div>
            {dateInitiatedRadio === eventStatusDateType.EXPLANATION ? (
              <div className="event-status-date-explanation-container">
                <label
                  htmlFor="dateInitiatedExplanation"
                  className="event-status-date-exp"
                  id="dateInitiatedExplanationTextLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  id="dateInitiatedExplanation"
                  onChange={onDateFirstChargedExplanationChange}
                  className={
                    dateInitiatedExpError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  onBlur={onBlurDateFirstChargedExpHandler}
                  onFocus={() => setFocusedState(true)}
                  rows="3"
                  cols={500}
                  value={dateInitiatedExp}
                />
                <div id="dateInitiatedExpError" className="invalid-feedback">
                  Please enter an Explanation
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <label
            className="org-name"
            htmlFor="docketCaseId"
            id="docketCaseLabelId"
          >
            Docket/Case #: *
          </label>
          <input
            type="text"
            id="docketCaseId"
            className={
              docketOrCaseError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            minLength={2}
            maxLength={100}
            autoComplete="off"
            value={docketOrCase}
            onChange={onChangeDocketOrCaseHandler}
            onBlur={onBlurDocketOrCaseHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="docketOrCaseError" className="invalid-feedback">
            Please enter Docket/Case #
          </div>
        </div>
      </div>

      <div className="row empl-firm">
        <div className="col-md-6">
          <label
            id="emplRegActionLabelId"
            className="pos-title-rel"
            htmlFor="emplRegAction"
          >
            Employing firm when activity occurred which led to the regulatory
            action: *
          </label>
          <input
            type="text"
            id="emplRegAction"
            className={
              emplRegActionError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={emplRegAction}
            onBlur={onBlurEmplRegActioHandler}
            onChange={onChangeEmplRegActionHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="emplRegActionError" className="invalid-feedback">
            Please enter Employing firm when activity occurred which led to the
            regulatory action
          </div>
        </div>
      </div>

      <CheckboxList
        selectedItems={selectedProdValues}
        list={DRPProdCheckList}
        header="Product Type(s) (Select all that apply): *"
        onChangeChecked={onChangeCheckedProd}
        onOtherChangeHandler={onOtherProdChangeHandler}
        onBlurOtherHandler={onBlurProdOtherHandler}
        onFocusHandler={() => setFocusedState(true)}
        otherTextError={otherProdTextError}
        otherText={otherProdText}
      />

      <div className="crim-comments-container">
        <label
          id="regActionAllegationDesLabelId"
          htmlFor="regActionAllegationDes"
          className="comments-label"
        >
          Describe the allegations related to this regulatory action: *
        </label>
        <textarea
          id="regActionAllegationDes"
          onChange={onRegActionAllegationDesChangeHandler}
          onBlur={onBlurRegActionAllegationDesHandler}
          onFocus={() => setFocusedState(true)}
          className={
            regActionAllegationDesError
              ? "form-control form-control-15 is-invalid"
              : "form-control form-control-15"
          }
          minLength={2}
          maxLength={500}
          rows="3"
          cols={500}
          value={regActionAllegationDes}
        />
        <div id="regActionAllegationDesError" className="invalid-feedback">
          Please enter the allegations related to this regulatory action
        </div>
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span id="regBack" role="presentation" onClick={handleShowBackModal}>
            <strong id="regBackText">Back</strong>
          </span>
          <Button
            label="Next"
            id="regNext"
            onClick={handleNext}
            disabled={isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default RegActionDetailsForm;
