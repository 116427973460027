import { useEffect } from "react";

export const ScrollBehavior = Object.freeze({
  AUTO: "auto",
  SMOOTH: "smooth",
});

const useScrollToAction = ({
  action,
  ref,
  behavior = ScrollBehavior.SMOOTH,
}) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (action && ref?.current) {
        ref?.current?.scroll({
          top: ref?.current?.scrollHeight,
          behavior,
        });
      }
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [action, ref, behavior]);
};

export default useScrollToAction;
