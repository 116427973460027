/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import classNames from "classnames";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { DatePicker } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import _ from "underscore";
import InfoTooltip from "./InfoTooltip";
import {
  DATE_FORMAT,
  DATE_FORMAT_API,
  isNotNullUndefinedOrEmpty,
} from "../../../../common/Functions";
import "../../../../assets/stylesheets/ConfidentialApplication/ConfidentialApplication.scss";

const plantTypeOptions = {
  ALL_INCENTIVE: "All Incentive",
  FLEX_IXP: "Flex IXP",
};

const weeklyfinancingamountOptions = {
  0: "$0",
  100: "$100",
  150: "$150",
  200: "$200",
  250: "$250",
  300: "$300",
  350: "$350",
  400: "$400",
  450: "$450",
  500: "$500",
};

const fycPayoutOptions = {
  0: "0%",
  5: "5%",
  10: "10%",
  15: "15%",
  20: "20%",
  25: "25%",
  30: "30%",
  35: "35%",
  40: "40%",
  45: "45%",
  50: "50%",
  55: "55%",
  60: "60%",
  65: "65%",
  70: "70%",
  75: "75%",
  80: "80%",
  85: "85%",
  90: "90%",
  95: "95%",
  100: "100%",
};

const fycBonusOptions = {
  0: "0%",
  5: "5%",
  10: "10%",
  15: "15%",
  20: "20%",
  25: "25%",
  30: "30%",
  35: "35%",
  40: "40%",
  45: "45%",
  50: "50%",
};

const clearedForm = {
  plantype: "",
  weeklyfinancingamount: "",
  fycpayout: "25",
  fycbonus: "25",
  // financingstartdate: null,
};

const cleanOutput = (data) => ({
  ...data,
  weeklyfinancingamount:
    plantTypeOptions[data.plantype] === plantTypeOptions.FLEX_IXP &&
    data.weeklyfinancingamount
      ? Number(data.weeklyfinancingamount)
      : undefined,
  fycpayout:
    plantTypeOptions[data.plantype] === plantTypeOptions.FLEX_IXP &&
    data.fycpayout
      ? Number(data.fycpayout)
      : undefined,
  fycbonus:
    plantTypeOptions[data.plantype] === plantTypeOptions.FLEX_IXP &&
    data.fycbonus
      ? Number(data.fycbonus)
      : undefined,
  financingstartdate: data.financingstartdate
    ? moment(data.financingstartdate).format(DATE_FORMAT_API)
    : undefined,
});

const cleanInput = (financingDetails) => {
  return {
    financingdetailsid: financingDetails?.financingdetailsid ?? undefined,
    contractid: financingDetails?.contractid ?? undefined,
    candidateid: financingDetails?.candidateid ?? undefined,
    plantype: financingDetails?.plantype ?? clearedForm.plantype,
    weeklyfinancingamount:
      !financingDetails?.weeklyfinancingamount &&
      financingDetails?.weeklyfinancingamount !== 0
        ? clearedForm.weeklyfinancingamount
        : String(financingDetails.weeklyfinancingamount),
    fycpayout:
      !financingDetails?.fycpayout && financingDetails?.fycpayout !== 0
        ? clearedForm.fycpayout
        : String(financingDetails.fycpayout),
    fycbonus:
      !financingDetails?.fycbonus && financingDetails?.fycbonus !== 0
        ? clearedForm.fycbonus
        : String(financingDetails.fycbonus),
    financingstartdate: financingDetails?.financingstartdate
      ? moment(financingDetails.financingstartdate, DATE_FORMAT_API).toDate()
      : clearedForm.financingstartdate,
  };
};

const FinancingDetailsForm = forwardRef((props, ref) => {
  const {
    onChange,
    financingDetails,
    readOnly,
    fullWidth,
    dataView,
    disabled,
    id,
    contract_details,
  } = props;

  const defaultValues = useMemo(
    () => cleanInput(financingDetails),
    [financingDetails]
  );

  const {
    register,
    watch,
    trigger,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
    setError,
  } = useForm({
    defaultValues,
    mode: "all",
    disabled,
  });

  useImperativeHandle(ref, () => ({
    getValues: () => cleanOutput({ ...getValues() }),
    isValid: async () => await trigger(),
    reset: () => reset(defaultValues),
  }));

  const watchForm = watch();

  useEffect(() => {
    if (!_.isEqual(watchForm, defaultValues) && onChange) {
      onChange(cleanOutput(watchForm));
    }
  }, [watchForm, defaultValues]);

  const planTypeWatch = watch("plantype");

  useEffect(() => {
    // hack for showing a placeholder for the datepicker component (this should be removed when we update the blueprint library)
    document
      .querySelector(".financing-start-date-wrapper input")
      ?.setAttribute("placeholder", "Must select a Wednesday");
  }, []);

  useEffect(() => {
    if (isDirty) {
      reset(
        {
          ...defaultValues,
          ...clearedForm,
          plantype: planTypeWatch,
        },
        { keepErrors: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planTypeWatch]);

  const showFlexIxpOptions =
    plantTypeOptions[planTypeWatch] === plantTypeOptions.FLEX_IXP;

  if (readOnly) {
    const {
      plantype,
      weeklyfinancingamount,
      fycpayout,
      fycbonus,
      financingstartdate,
    } = financingDetails ?? {};

    return (
      <>
        <div className={classNames("row mb-3")}>
          <div
            id="plantype"
            className={classNames({ "col-4": !dataView, "col-5": dataView })}
          >
            <strong>Plan Type*</strong>
          </div>
          <div id="plantype_value" className="col-7 bo--text-color">
            {plantTypeOptions[plantype] ?? "-"}
          </div>
        </div>
        {plantTypeOptions[plantype] === plantTypeOptions.FLEX_IXP && (
          <>
            <div className={classNames("row mb-3")}>
              <div
                id="weeklyfinancingamount"
                className={classNames({
                  "col-4": !dataView,
                  "col-5": dataView,
                })}
              >
                <strong>Weekly Financing Amount*</strong>
              </div>
              <div
                id="weeklyfinancingamount_value"
                className="col-7 bo--text-color"
              >
                {`$ ${weeklyfinancingamount}`}
              </div>
            </div>
            <div className={classNames("row mb-3")}>
              <div
                id="fycpayout"
                className={classNames({
                  "col-4": !dataView,
                  "col-5": dataView,
                })}
              >
                <strong>FYC Payout*</strong>
              </div>
              <div id="fycpayout_value" className="col-7 bo--text-color">
                {fycPayoutOptions[fycpayout] ?? "-"}
              </div>
            </div>
            <div className={classNames("row mb-3")}>
              <div
                id="fycbonus"
                className={classNames({
                  "col-4": !dataView,
                  "col-5": dataView,
                })}
              >
                <strong>FYC Bonus*</strong>
              </div>
              <div id="fycbonus_value" className="col-7 bo--text-color">
                {fycBonusOptions[fycbonus] ?? "-"}
              </div>
            </div>
          </>
        )}

        <div className={classNames("row")}>
          <div
            id="financing_start_date"
            className={classNames({ "col-4": !dataView, "col-5": dataView })}
          >
            <strong>Financing Start Date*</strong>
          </div>
          <div id="financing_start_date_value" className="col-7 bo--text-color">
            {financingstartdate
              ? moment(financingstartdate, DATE_FORMAT_API).format(DATE_FORMAT)
              : "-"}
          </div>
        </div>
        {id === "confidential" && (
          <>
            <hr></hr>
            <div className="row">
              <div className="col-5">
                <label id="label_pantype">
                  <strong>Comments from Agency</strong>
                </label>
              </div>
              <div
                id="fycpayout_value"
                className="col-7 bo--text-color word-break"
              >
                {isNotNullUndefinedOrEmpty(contract_details?.comment)
                  ? contract_details.comment
                  : "-"}
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <form className="review-form">
      <div className="row">
        <div
          className={classNames({
            "col-md-4": !fullWidth,
            "col-md-12": fullWidth,
          })}
        >
          <label id="label_pantype">
            <strong>Please select a Plan Type*</strong>
          </label>
          {Object.entries(plantTypeOptions).map(([value, label]) => (
            <Controller
              control={control}
              name={"plantype"}
              rules={{
                required: {
                  value: !!financingDetails?.plantype,
                  message: "Please choose an answer",
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <div
                  key={`radio-plantype-${value}`}
                  className="custom-control custom-radio"
                >
                  <input
                    type="radio"
                    id={`plantype_${value}`}
                    className={classNames("custom-control-input", {
                      "is-invalid": !!errors?.plantype || !!error?.message,
                    })}
                    ref={ref}
                    {...field}
                    {...register("plantype", {
                      required: "Please choose an answer",
                    })}
                    value={value}
                    defaultValue={financingDetails?.plantype}
                    onChange={(e) => {
                      field.onChange(e);

                      setError("weeklyfinancingamount", "");
                      setError("fycpayout", "");
                      setError("fycbonus", "");
                    }}
                    checked={value === financingDetails?.plantype}
                  />
                  <label
                    htmlFor={`plantype_${value}`}
                    className="custom-control-label"
                    id={`label_plantype_${value}`}
                  >
                    {label}
                  </label>
                </div>
              )}
            />
          ))}
          <span
            className={classNames("invalid-feedback", {
              show: !!errors?.plantype,
            })}
          >
            {errors?.plantype?.message}
          </span>
        </div>
      </div>

      {showFlexIxpOptions && (
        <>
          <div className="row mt-2">
            <div
              className={classNames({
                "col-md-4": !fullWidth,
                "col-md-12": fullWidth,
              })}
            >
              <label id="label_weeklyfinancingamount">
                <strong>Weekly Financing Amount*</strong>
              </label>
              <Controller
                control={control}
                name="weeklyfinancingamount"
                rules={{
                  required: {
                    value: showFlexIxpOptions,
                    message: "Please select a response",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref, disabled },
                  fieldState: { error },
                }) => (
                  <div
                    className={classNames("dropdownbutton-wrapper", {
                      "is-invalid": !!error?.message,
                    })}
                  >
                    <DropdownButton
                      ref={ref}
                      id="weeklyfinancingamount"
                      type="default"
                      variant="secondary"
                      disabled={disabled}
                      title={value ? weeklyfinancingamountOptions[value] : ""}
                      selected={false}
                      onSelect={(key, event) => onChange(key)}
                      onBlur={onBlur}
                      style={{ width: "165px" }}
                    >
                      {Object.entries(weeklyfinancingamountOptions).map(
                        ([value, label]) => (
                          <Dropdown.Item
                            key={`weeklyfinancingamount-option-${value}`}
                            eventKey={value}
                            id={`weeklyfinancingamount-option-${value}`}
                          >
                            {label}
                          </Dropdown.Item>
                        )
                      )}
                    </DropdownButton>
                    <span
                      className={classNames("invalid-feedback", {
                        show: !!errors?.weeklyfinancingamount,
                      })}
                    >
                      {error?.message}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div
              className={classNames({
                "col-md-4": !fullWidth,
                "col-md-12": fullWidth,
              })}
            >
              <label id="label_fycpayout">
                <strong>FYC Payout*</strong>
              </label>
              <Controller
                control={control}
                name="fycpayout"
                rules={{
                  required: {
                    value: showFlexIxpOptions,
                    message: "Please select a response",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref, disabled },
                  fieldState: { invalid, error },
                }) => (
                  <div
                    className={classNames("dropdownbutton-wrapper", {
                      "is-invalid": !!error?.message,
                    })}
                  >
                    <DropdownButton
                      ref={ref}
                      disabled={disabled}
                      id="fycpayout"
                      type="default"
                      variant="secondary"
                      title={value ? fycPayoutOptions[value] : ""}
                      selected={false}
                      onSelect={(key, _) => {
                        if (key === "100") {
                          setValue("fycbonus", "0");
                        }
                        onChange(key);
                      }}
                      onBlur={onBlur}
                      style={{ width: "165px" }}
                    >
                      {Object.entries(fycPayoutOptions).map(
                        ([value, label]) => (
                          <Dropdown.Item
                            key={`fycpayout-option-${value}`}
                            eventKey={value}
                            id={`fycpayout-option-${value}`}
                          >
                            {label}
                          </Dropdown.Item>
                        )
                      )}
                    </DropdownButton>
                    <span
                      className={classNames("invalid-feedback", {
                        show: !!errors?.fycpayout,
                      })}
                    >
                      {error?.message}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div
              className={classNames({
                "col-md-4": !fullWidth,
                "col-md-12": fullWidth,
              })}
            >
              <label id="label_fycbonus">
                <strong>FYC Bonus*</strong>
              </label>
              <Controller
                control={control}
                name="fycbonus"
                rules={{
                  required: {
                    value: showFlexIxpOptions,
                    message: "Please select a response",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref, disabled },
                  fieldState: { error },
                }) => (
                  <div
                    className={classNames("dropdownbutton-wrapper", {
                      "is-invalid": !!error?.message,
                    })}
                  >
                    <DropdownButton
                      disabled={disabled}
                      ref={ref}
                      id="fycbonus"
                      type="default"
                      variant="secondary"
                      title={value ? fycBonusOptions[value] : ""}
                      selected={false}
                      onSelect={(key, event) => onChange(key)}
                      onBlur={onBlur}
                      style={{ width: "165px" }}
                    >
                      {Object.entries(fycBonusOptions).map(([value, label]) => (
                        <Dropdown.Item
                          key={`fycbonus-option-${value}`}
                          eventKey={value}
                          id={`fycbonus-option-${value}`}
                        >
                          {label}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    <span
                      className={classNames("invalid-feedback", {
                        show: !!errors?.fycbonus,
                      })}
                    >
                      {error?.message}
                    </span>
                  </div>
                )}
              />
            </div>
          </div>
        </>
      )}
      <div className="row mt-4">
        <div
          className={classNames({
            "col-md-5": !fullWidth,
            "col-md-12": fullWidth,
          })}
        >
          <label id="label_financingstartdate">
            <strong>Financing Start Date*</strong>
          </label>
          <InfoTooltip
            placement="top"
            text={
              "Financing Start Date must be a Wednesday within the last two weeks."
            }
          />
          <Controller
            control={control}
            name="financingstartdate"
            rules={{
              required: "Please enter a valid date",
              validate: {
                is30DaysPrior: (v) =>
                  moment().diff(moment(v), "days") <= 30 ||
                  "Financing Start Date cannot be more than 30 days prior",
                isWendnesday: (v) =>
                  moment(v).weekday() === 3 ||
                  "Financing Start Date must be a Wednesday",
              },
            }}
            render={({
              field: { onChange, onBlur, value, disabled },
              fieldState: { error },
            }) => (
              <div
                className={classNames(
                  "datepicker-wrapper financing-start-date-wrapper",
                  {
                    "is-invalid": !!error?.message && !disabled,
                    "datepicker-disabled": disabled,
                  }
                )}
              >
                <DatePicker
                  disabled={disabled}
                  label=""
                  selected={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                <span
                  className={classNames("invalid-feedback", {
                    show: !!errors?.financingstartdate && !disabled,
                  })}
                >
                  {error?.message}
                </span>
              </div>
            )}
          />
        </div>
      </div>
    </form>
  );
});

export default FinancingDetailsForm;
