/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { Dropdown, DropdownButton } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { logoutActions } from "../../../../../../../store/actions";
import BackButtonModal from "../../../../../Forms/BackButtonModal";
import moment from "moment";

import "./CivilLitigation.scss";

import {
  clickFunc,
  dateValidator,
  formatMoney,
  onlyNumeric,
  pasteCommaDelimited,
} from "../../../../../../../common/Functions";
import {
  civLitStatusOptions,
  courtType,
  exactExplanationOptions,
  yesNoOptions,
} from "../constants";

const CustomerComplaintAddDRPCivilLitigationForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  tempDRP,
  updateTempCustComplDRP,
}) => {
  const dispatch = useDispatch();
  /************************************
   * INITIALIZE USER DATA STATE ITEMS *
   ************************************/
  const [showBackModal, setShowBackModal] = useState(false);
  const [civLitFiledCourtType, setFiledCourtType] = useState(
    tempDRP?.civLitFiledCourtType || ""
  );
  const [civLitFiledCourtTypeOther, setFiledCourtTypeOther] = useState(
    tempDRP?.civLitFiledCourtTypeOther || ""
  );
  const [civLitFiledCourtName, setFiledCourtName] = useState(
    tempDRP?.civLitFiledCourtName || ""
  );
  const [civLitFiledCourtLocation, setFiledCourtLocation] = useState(
    tempDRP?.civLitFiledCourtLocation || ""
  );
  const [civLitFiledDocketOrCase, setFiledDocketOrCase] = useState(
    tempDRP?.civLitFiledDocketOrCase || ""
  );
  const [civLitServedDate, setServedDate] = useState(
    tempDRP?.civLitServedDate || ""
  );
  const [civLitServedDateRadio, setServedDateRadio] = useState(
    tempDRP?.civLitServedDateRadio || ""
  );
  const [civLitServedDateExplanation, setServedDateRadioExplanation] = useState(
    tempDRP?.civLitServedDateExplanation || ""
  );
  const [civLitIsPending, setIsPending] = useState(
    tempDRP?.civLitIsPending || ""
  );
  const [civLitDispositionOption, setDispositionOption] = useState(
    tempDRP?.civLitDispositionOption || ""
  );
  const [
    civLitDispositionOptionOtherExplanation,
    setDispositionOptionOtherExplanation,
  ] = useState(tempDRP?.civLitDispositionOptionOtherExplanation || "");
  const [civLitMonetaryComp, setMonetaryComp] = useState(
    tempDRP?.civLitMonetaryComp || ""
  );
  const [civLitContribAmount, setContribAmount] = useState(
    tempDRP?.civLitContribAmount || ""
  );
  const [civLitDispositionDate, setDispositionDate] = useState(
    tempDRP?.civLitDispositionDate || ""
  );
  const [civLitDispositionDateRadio, setDispositionDateRadio] = useState(
    tempDRP?.civLitDispositionDateRadio || ""
  );
  const [civLitDispositionDateExplanation, setDispositionDateExplanation] =
    useState(tempDRP?.civLitDispositionDateExplanation || "");

  const [civLitIsOnAppeal, setIsOnAppeal] = useState(
    tempDRP?.civLitIsOnAppeal || ""
  );
  const [civLitAppealFileDate, setAppealFileDate] = useState(
    tempDRP?.civLitAppealFileDate || ""
  );
  const [civLitAppealFileDateExplanation, setAppealFileDateExplanation] =
    useState(tempDRP?.civLitAppealFileDateExplanation || "");
  const [civLitAppealFileDateRadio, setAppealFileDateRadio] = useState(
    tempDRP?.civLitAppealFileDateRadio || ""
  );
  const [civLitAppealCourtType, setAppealCourtType] = useState(
    tempDRP?.civLitAppealCourtType || ""
  );
  const [civLitAppealFileCourtTypeOther, setAppealFileCourtTypeOther] =
    useState(tempDRP?.civLitAppealFileCourtTypeOther || "");
  const [civLitAppealCourtName, setAppealCourtName] = useState(
    tempDRP?.civLitAppealCourtName || ""
  );
  const [civLitAppealCourtLocation, setAppealCourtLocation] = useState(
    tempDRP?.civLitAppealCourtLocation || ""
  );
  const [civLitAppealDocketOrCase, setAppealDocketOrCase] = useState(
    tempDRP?.civLitAppealDocketOrCase || ""
  );

  /************************************
   * INITIALIZE ERROR STATE ITEMS *
   ************************************/
  const [civLitFiledCourtTypeOtherError, setFiledCourtTypeOtherError] =
    useState(false);
  const [civLitFiledCourtNameError, setFiledCourtNameError] = useState(false);
  const [civLitFiledCourtLocationError, setFiledCourtLocationError] =
    useState(false);
  const [civLitFiledDocketOrCaseError, setFiledDocketOrCaseError] =
    useState(false);
  const [civLitServedDateError, setServedDateError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [civLitServedDateErrorMsg, setServedDateErrorMsg] = useState("");
  const [civLitServedDateExplanationError, setServedDateRadioExplanationError] =
    useState(false);
  const [civLitDispositionOptionError, setDispositionOptionError] =
    useState(false);
  const [
    civLitDispositionOptionExplanationError,
    // eslint-disable-next-line no-unused-vars
    setDispositionOptionExplanationError,
  ] = useState(false);
  const [civLitMonetaryCompError, setMonetaryCompError] = useState(false);
  const [civLitMonetaryCompErrorMsg, setMonetaryCompErrorMsg] = useState("");
  const [civLitContribAmountError, setContribAmountError] = useState(false);
  const [civLitContribAmountErrorMsg, setContribAmountErrorMsg] = useState("");
  const [civLitDispositionDateError, setDispositionDateError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [civLitDispositionDateErrorMsg, setDispositionDateErrorMsg] =
    useState("");
  const [
    civLitDispositionDateExplanationError,
    setDispositionDateExplanationError,
  ] = useState(false);
  const [
    civLitDispositionOptionOtherExplanationError,
    setDispositionOptionOtherExplanationError,
  ] = useState(false);
  const [
    civLitAppealFileCourtTypeOtherError,
    setAppealFileCourtTypeOtherError,
  ] = useState(false);
  const [civLitAppealFileDateError, setAppealFileDateError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [civLitAppealFileDateErrorMsg, setAppealFileDateErrorMsg] =
    useState(false);
  const [
    civLitAppealFileDateExplanationError,
    setAppealFileDateExplanationError,
  ] = useState(false);
  const [civLitAppealCourtNameError, setAppealCourtNameError] = useState(false);
  const [civLitAppealCourtLocationError, setAppealCourtLocationError] =
    useState(false);
  const [civLitAppealDocketOrCaseError, setAppealDocketOrCaseError] =
    useState(false);

  const [isUpdates, setIsUpdates] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  /******************************************
   * isError and isNextBtnDisabled are used *
   * to determine if user can proceed       *
   *****************************************/
  const isError =
    civLitFiledCourtTypeOtherError ||
    civLitFiledCourtNameError ||
    civLitFiledCourtLocationError ||
    civLitFiledDocketOrCaseError ||
    civLitServedDateError ||
    civLitServedDateExplanationError ||
    civLitDispositionOptionError ||
    civLitDispositionOptionExplanationError ||
    civLitMonetaryCompError ||
    civLitContribAmountError ||
    civLitDispositionDateError ||
    civLitDispositionDateExplanationError ||
    civLitAppealFileCourtTypeOtherError ||
    civLitAppealFileDateError ||
    civLitAppealFileDateExplanationError ||
    civLitAppealCourtNameError ||
    civLitAppealDocketOrCaseError ||
    civLitDispositionOptionOtherExplanationError;

  const isNextBtnDisabled =
    civLitFiledCourtType?.length < 2 ||
    (civLitFiledCourtType === courtType.OTHER &&
      civLitFiledCourtTypeOther?.length < 2) ||
    civLitFiledCourtName?.length < 2 ||
    civLitFiledCourtLocation?.length < 2 ||
    civLitFiledDocketOrCase?.length < 2 ||
    civLitServedDate?.length < 2 ||
    civLitServedDate.includes("_") ||
    dateValidator(civLitServedDate).isInvalid ||
    civLitServedDateRadio?.length < 2 ||
    (civLitServedDateRadio === exactExplanationOptions.EXPLANATION &&
      civLitServedDateExplanation?.length < 2) ||
    civLitIsPending?.length < 2 ||
    (civLitIsPending === yesNoOptions.NO &&
      (civLitDispositionOption?.length < 2 ||
        (civLitDispositionOption?.length === "Other" &&
          civLitDispositionOptionOtherExplanation?.length < 2) ||
        ((civLitDispositionOption === "Settled" ||
          civLitDispositionOption.includes("Monetary")) &&
          (civLitMonetaryComp?.length < 1 ||
            civLitContribAmount?.length < 1)))) ||
    civLitDispositionDate?.length < 2 ||
    civLitDispositionDate.includes("_") ||
    dateValidator(civLitDispositionDate).isInvalid ||
    civLitDispositionDateRadio?.length < 2 ||
    (civLitDispositionDateRadio === exactExplanationOptions.EXPLANATION &&
      civLitDispositionDateExplanation?.length < 2) ||
    civLitIsOnAppeal?.length < 2 ||
    (civLitIsOnAppeal === yesNoOptions.YES &&
      (civLitAppealFileDate?.length < 2 ||
        civLitAppealFileDate.includes("_") ||
        dateValidator(civLitAppealFileDate).isInvalid ||
        civLitAppealCourtType?.length < 2 ||
        civLitAppealFileDateRadio?.length < 2 ||
        (civLitAppealFileDateRadio === exactExplanationOptions.EXPLANATION &&
          civLitAppealFileDateExplanation?.length < 2) ||
        (civLitAppealCourtType === courtType.OTHER &&
          civLitAppealFileCourtTypeOther?.length < 2) ||
        civLitAppealCourtName?.length < 2 ||
        civLitAppealCourtLocation?.length < 2 ||
        civLitAppealDocketOrCase?.length < 2)) ||
    isError ||
    isFocusedState;

  /***************************************************************************
   * handleChangeChecked & handleAppealChangeChecked                         *
   * handle the selection of Court Types for Appeal and Court Filed Sections *
   ***************************************************************************/
  const handleChangeChecked = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value !== courtType.OTHER) {
      setFiledCourtTypeOtherError(false);
      setFiledCourtTypeOther("");
    }
    setFiledCourtType(value);
  };
  const handleAppealChangeChecked = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value !== courtType.OTHER) {
      setAppealFileCourtTypeOther("");
      setAppealFileCourtTypeOtherError(false);
    }
    setAppealCourtType(value);
  };

  /***************************************************
   * This checks if the user entered any information *
   ***************************************************/
  // const isUpdates =
  //   civLitFiledCourtName?.length ||
  //   civLitFiledCourtLocation?.length ||
  //   civLitFiledDocketOrCase?.length ||
  //   civLitFiledCourtType?.length ||
  //   (civLitFiledCourtType?.length &&
  //     civLitFiledCourtType === courtType.OTHER &&
  //     civLitFiledCourtTypeOther?.length) ||
  //   isError;

  /***************************************************
   * if user updated page and then hit logout button *
   ***************************************************/
  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: true }));
  }, [isUpdates, isError, dispatch]);

  /************************
   * Navigation Functions *
   ************************/
  const handleShowBackModal = () => {
    if (isUpdates || isError) {
      setShowBackModal(true);
    } else {
      updateCCBForm(backFormId);
    }
  };
  const handleBackClick = () => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };
  const handleCloseBackModal = () => setShowBackModal(false);
  const handleNext = () => {
    const submitData = {
      ...tempDRP,
      civLitFiledCourtType,
      civLitFiledCourtTypeOther,
      civLitFiledCourtName,
      civLitFiledCourtLocation,
      civLitFiledDocketOrCase,
      civLitServedDate,
      civLitServedDateRadio,
      civLitServedDateExplanation,
      civLitIsPending,
      civLitDispositionOption,
      civLitDispositionOptionOtherExplanation,
      civLitMonetaryComp,
      civLitContribAmount,
      civLitDispositionDate,
      civLitDispositionDateRadio,
      civLitDispositionDateExplanation,
      civLitIsOnAppeal,
      civLitAppealFileDate,
      civLitAppealFileDateExplanation,
      civLitAppealFileDateRadio,
      civLitAppealCourtType,
      civLitAppealFileCourtTypeOther,
      civLitAppealCourtName,
      civLitAppealCourtLocation,
      civLitAppealDocketOrCase,
    };
    updateTempCustComplDRP(submitData);
    updateCCBForm(nextFormId);
  };

  /*********************************
   * Updates State on Input Change *
   *********************************/
  const onChangeTextInput = ({ target: { id, value } }) => {
    setIsUpdates(true);
    switch (id) {
      case "otherText":
        return setFiledCourtTypeOther(value);
      case "civLitFiledCourtName":
        return setFiledCourtName(value);
      case "civLitFiledCourtLocation":
        return setFiledCourtLocation(value);
      case "civLitFiledDocketOrCase":
        return setFiledDocketOrCase(value);
      case "civLitServedDate":
        return setServedDate(value);
      case "civLitServedDateExplanation":
        return setServedDateRadioExplanation(value);
      case "civLitDispositionOptionOtherExplanation":
        return setDispositionOptionOtherExplanation(value);
      case "civLitMonetaryComp":
        return setMonetaryComp(formatMoney(value));
      case "civLitContribAmount":
        return setContribAmount(formatMoney(value));
      case "civLitDispositionDate":
        return setDispositionDate(value);
      case "civLitDispositionDateExplanation":
        return setDispositionDateExplanation(value);
      case "civLitAppealFileDate":
        return setAppealFileDate(value);
      case "civLitAppealFileDateExplanation":
        return setAppealFileDateExplanation(value);
      case "appealOtherText":
        return setAppealFileCourtTypeOther(value);
      case "civLitAppealCourtName":
        return setAppealCourtName(value);
      case "civLitAppealCourtLocation":
        return setAppealCourtLocation(value);
      case "civLitAppealDocketOrCase":
        return setAppealDocketOrCase(value);
      default:
        break;
    }
  };

  /************************
   * Validates Text Input *
   ************************/
  const onBlurTextInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    const isInvalid = value?.trim()?.length < 2;
    switch (id) {
      case "otherText":
        return setFiledCourtTypeOtherError(isInvalid);
      case "civLitFiledCourtName":
        return setFiledCourtNameError(isInvalid);
      case "civLitFiledCourtLocation":
        return setFiledCourtLocationError(isInvalid);
      case "civLitFiledDocketOrCase":
        return setFiledDocketOrCaseError(isInvalid);
      case "civLitServedDateExplanation":
        return setServedDateRadioExplanationError(isInvalid);
      case "civLitDispositionDateExplanation":
        return setDispositionDateExplanationError(isInvalid);
      case "civLitDispositionOptionOtherExplanation":
        return setDispositionOptionOtherExplanationError(isInvalid);
      case "civLitAppealFileDateExplanation":
        return setAppealFileDateExplanationError(isInvalid);
      case "appealOtherText":
        return setAppealFileCourtTypeOtherError(isInvalid);
      case "civLitAppealCourtName":
        return setAppealCourtNameError(isInvalid);
      case "civLitAppealCourtLocation":
        return setAppealCourtLocationError(isInvalid);
      case "civLitAppealDocketOrCase":
        return setAppealDocketOrCaseError(isInvalid);
      default:
        break;
    }
  };
  /************************
   * Validates Date Input *
   ************************/
  const onBlurDateInput = ({ target: { id, value } }) => {
    setFocusedState(false);
    const { isInvalid, errMessage } = dateValidator(value);
    const today = moment();
    const date = moment(value, "MM/DD/YYYY", true);
    const past = moment(moment().subtract(100, "years").format("MM/DD/YYYY"));

    switch (id) {
      case "civLitServedDate":
        if (value !== "" && !value.includes("_")) {
          if (moment(value) > today) {
            setServedDateError(true);
          } else {
            setServedDateError(false);
          }
        } else {
          setServedDateError(false);
        }

        if (
          date._i.length === 10 &&
          !date._i.includes("_") &&
          date.isValid() &&
          !value.includes("0000")
        ) {
          if (date > today) {
            setServedDateErrorMsg("Date cannot be a future date");
            setServedDateError(true);
          } else if (date < past) {
            setServedDateErrorMsg("Date cannot be more than 100 years ago");
            setServedDateError(true);
          } else {
            setServedDateError(false);
          }
        } else {
          setServedDateErrorMsg("Please enter Date received by/served on firm");
          setServedDateError(true);
        }
        break;
      case "civLitDispositionDate":
        if (value !== "" && !value.includes("_")) {
          if (moment(value) > today) {
            setDispositionDateError(true);
          } else {
            setDispositionDateError(false);
          }
        } else {
          setDispositionDateError(false);
        }

        if (
          date._i.length === 10 &&
          !date._i.includes("_") &&
          date.isValid() &&
          !value.includes("0000")
        ) {
          if (date > today) {
            setDispositionDateErrorMsg("Date cannot be a future date");
            setDispositionDateError(true);
          } else if (date < past) {
            setDispositionDateErrorMsg(
              "Date cannot be more than 100 years ago"
            );
            setDispositionDateError(true);
          } else {
            setDispositionDateError(false);
          }
        } else {
          setDispositionDateErrorMsg("Please enter Disposition date");
          setDispositionDateError(true);
        }
        break;
      case "civLitAppealFileDate":
        if (value !== "" && !value.includes("_")) {
          if (moment(value) > today) {
            setAppealFileDateError(true);
          } else {
            setAppealFileDateError(false);
          }
        } else {
          setAppealFileDateError(false);
        }

        if (
          date._i.length === 10 &&
          !date._i.includes("_") &&
          date.isValid() &&
          !value.includes("0000")
        ) {
          if (date > today) {
            setAppealFileDateErrorMsg("Date cannot be a future date");
            setAppealFileDateError(true);
          } else if (date < past) {
            setAppealFileDateErrorMsg("Date cannot be more than 100 years ago");
            setAppealFileDateError(true);
          } else {
            setAppealFileDateError(false);
          }
        } else {
          setAppealFileDateErrorMsg("Please enter Date appeal filed");
          setAppealFileDateError(true);
        }
        break;
      default:
        break;
    }
  };

  /*************************
   * Validates Money Input *
   *************************/
  const onBlurMoneyInput = ({ target: { id, value } }) => {
    const isMoneyError =
      parseInt(value?.replaceAll(",", "")) > 100000000 || value?.length < 1;
    setFocusedState(false);

    let moneyErrMessage;
    switch (id) {
      case "civLitMonetaryComp":
        moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter Monetary Compensation Details"
            : "";
        setMonetaryCompErrorMsg(moneyErrMessage);
        setMonetaryCompError(isMoneyError);
        break;
      case "civLitContribAmount":
        moneyErrMessage =
          parseInt(value?.replaceAll(",", "")) > 100000000
            ? "Please enter a valid number"
            : value?.length < 1
            ? "Please enter your Contribution Amount"
            : "";
        setContribAmountErrorMsg(moneyErrMessage);
        setContribAmountError(isMoneyError);
        break;

      default:
        break;
    }
  };

  /*************************
   * Radio Button Handlers *
   *************************/
  const handleServedDateRadioCheckChange = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value === exactExplanationOptions.EXACT) {
      setServedDateRadioExplanation("");
      setServedDateRadioExplanationError(false);
    }
    setServedDateRadio(value);
  };
  const handleIsPendingChangeChecked = (e) => {
    setIsUpdates(true);
    const { value } = e.target;
    if (value === "Yes") {
      setDispositionOption("");
      setDispositionOptionError(false);
      setMonetaryComp("");
      setMonetaryCompError(false);
      setContribAmount("");
      setContribAmountError(false);
      setDispositionOptionOtherExplanation("");
      setDispositionOptionOtherExplanationError(false);
    }
    setIsPending(value);
  };
  const handleDispositionDateRadioCheckChange = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value === exactExplanationOptions.EXACT) {
      setDispositionDateExplanation("");
      setDispositionDateExplanationError(false);
    }
    setDispositionDateRadio(value);
  };
  const handleIsOnAppealChangeChecked = (e) => {
    setIsUpdates(true);
    const { value } = e.target;
    if (value === "No") {
      setAppealFileDate("");
      setAppealFileDateError(false);
      setAppealFileDateErrorMsg("");
      setAppealFileDateRadio("");
      setAppealFileDateExplanation("");
      setAppealFileDateExplanationError(false);
      setAppealCourtType("");
      setAppealCourtName("");
      setAppealCourtNameError(false);
      setAppealCourtLocation("");
      setAppealCourtLocationError(false);
      setAppealDocketOrCase("");
      setAppealDocketOrCaseError(false);
    }
    setIsOnAppeal(value);
  };
  const handleAppealDateRadioCheckChange = ({ target: { value } }) => {
    setIsUpdates(true);
    if (value === exactExplanationOptions.EXACT) {
      setAppealFileDateExplanation("");
      setAppealFileDateExplanationError(false);
    }
    setAppealFileDateRadio(value);
  };
  const onChangeStatusOptionHandler = ({ target: { value } }) => {
    setIsUpdates(true);
    setMonetaryComp("");
    setMonetaryCompError(false);
    setContribAmount("");
    setContribAmountError(false);
    setDispositionOptionOtherExplanation("");
    setDispositionOptionOtherExplanationError(false);
    setDispositionOption(value);
  };
  const onBlurDispositionOptionHandler = ({ relatedTarget }) => {
    setFocusedState(false);
    return civLitDispositionOption === "" && relatedTarget === null
      ? setDispositionOptionError(true)
      : setDispositionOptionError(false);
  };
  return (
    <div className="cust-complaint-disclosure-civil-litigation-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <label id="courtFiledLabel" className="customer-complaint-label">
            Court in which case was filed: *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="courtFiledTypeFederalCourt"
                type="radio"
                id="courtFiledTypeFederalCourt"
                className="custom-control-input"
                value={courtType.FEDERAL}
                checked={civLitFiledCourtType === courtType.FEDERAL}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="courtFiledTypeFederalCourt"
                className="custom-control-label"
                id="courtFiledTypeFederalCourtLabelId"
              >
                {courtType.FEDERAL}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="courtFiledTypeStateCourt"
                type="radio"
                id="courtFiledTypeStateCourt"
                className="custom-control-input"
                value={courtType.STATE}
                checked={civLitFiledCourtType === courtType.STATE}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="courtFiledTypeStateCourt"
                className="custom-control-label"
                id="courtFiledTypeStateCourtLabelId"
              >
                {courtType.STATE}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="courtFiledTypeForeignCourt"
                type="radio"
                id="courtFiledTypeForeignCourt"
                className="custom-control-input"
                value={courtType.FOREIGN}
                checked={civLitFiledCourtType === courtType.FOREIGN}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="courtFiledTypeForeignCourt"
                className="custom-control-label"
                id="courtFiledTypeForeignCourtLabelId"
              >
                {courtType.FOREIGN}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="courtFiledTypeMilitaryCourt"
                type="radio"
                id="courtFiledTypeMilitaryCourt"
                className="custom-control-input"
                value={courtType.MILITARY}
                checked={civLitFiledCourtType === courtType.MILITARY}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="courtFiledTypeMilitaryCourt"
                className="custom-control-label"
                id="courtFiledTypeMilitaryCourtLabelId"
              >
                {courtType.MILITARY}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="courtFiledTypeOther"
                type="radio"
                id="courtFiledTypeOther"
                className="custom-control-input"
                value={courtType.OTHER}
                checked={civLitFiledCourtType === courtType.OTHER}
                onChange={handleChangeChecked}
              />
              <label
                htmlFor="courtFiledTypeOther"
                className="custom-control-label"
                id="courtFiledTypeOtherLabelId"
              >
                {courtType.OTHER}
              </label>
            </div>

            {civLitFiledCourtType === courtType.OTHER ? (
              <div
                className="court-action-other"
                style={{ marginLeft: "25px" }}
              >
                <input
                  type="text"
                  id="otherText"
                  className={
                    civLitFiledCourtTypeOtherError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={civLitFiledCourtTypeOther}
                  onChange={onChangeTextInput}
                  onBlur={onBlurTextInput}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="civLitFiledCourtTypeOtherError"
                  className="invalid-feedback"
                >
                  Please enter a response
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" style={{ minWidth: "430px" }}>
          <label
            className="customer-complaint-label"
            htmlFor="civLitFiledCourtName"
            id="civLitCourtNameLabelId"
          >
            Name of court: *
          </label>
          <input
            type="text"
            id="civLitFiledCourtName"
            className={
              civLitFiledCourtNameError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={civLitFiledCourtName}
            onChange={onChangeTextInput}
            onBlur={onBlurTextInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="civLitFiledCourtNameError" className="invalid-feedback">
            Please enter Name of court
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" style={{ minWidth: "430px" }}>
          <label
            className="customer-complaint-label"
            htmlFor="civLitFiledCourtLocation"
            id="civLitCourtLocationLabelId"
          >
            Location of court (City or County and State or Country): *
          </label>
          <input
            type="text"
            id="civLitFiledCourtLocation"
            className={
              civLitFiledCourtLocationError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={civLitFiledCourtLocation}
            onChange={onChangeTextInput}
            onBlur={onBlurTextInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="civLitFiledCourtLocationError" className="invalid-feedback">
            Please enter Location of court
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" style={{ minWidth: "430px" }}>
          <label
            className="customer-complaint-label"
            htmlFor="civLitFiledDocketOrCase"
            id="civLitDocketOrCaseLabelId"
          >
            Docket/Case #: *
          </label>
          <input
            type="text"
            id="civLitFiledDocketOrCase"
            className={
              civLitFiledDocketOrCaseError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            maxLength={100}
            minLength={2}
            autoComplete="off"
            value={civLitFiledDocketOrCase}
            onChange={onChangeTextInput}
            onBlur={onBlurTextInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="civLitFiledDocketOrCaseError" className="invalid-feedback">
            Please enter Docket/Case #
          </div>
        </div>
      </div>

      <div className="row date-wrapper">
        <div className="col-md-5">
          <label
            className="customer-complaint-label"
            htmlFor="civLitServedDate"
            id="civLitServedDateLabelId"
          >
            Date received by/served on firm: *
          </label>
          <InputMask
            id="civLitServedDate"
            mask="99/99/9999"
            maskChar="_"
            className={
              civLitServedDateError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={civLitServedDate}
            onChange={onChangeTextInput}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="custComplaintDateReceivedError" className="invalid-feedback">
            {civLitServedDateErrorMsg}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="civLitServedDateRadioExact"
                type="radio"
                id="civLitServedDateRadioExact"
                className="custom-control-input"
                value={exactExplanationOptions.EXACT}
                checked={
                  civLitServedDateRadio === exactExplanationOptions.EXACT
                }
                onChange={handleServedDateRadioCheckChange}
              />
              <label
                htmlFor="civLitServedDateRadioExact"
                className="custom-control-label"
                id="civLitServedDateRadioExactLabelId"
              >
                {exactExplanationOptions.EXACT} date
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="civLitServedDateRadioExplanation"
                type="radio"
                id="civLitServedDateRadioExplanation"
                className="custom-control-input"
                value={exactExplanationOptions.EXPLANATION}
                checked={
                  civLitServedDateRadio === exactExplanationOptions.EXPLANATION
                }
                onChange={handleServedDateRadioCheckChange}
              />
              <label
                htmlFor="civLitServedDateRadioExplanation"
                className="custom-control-label"
                id="civLitServedDateRadioExplanationLabelId"
              >
                {exactExplanationOptions.EXPLANATION}
              </label>
            </div>
          </div>
        </div>
      </div>
      {civLitServedDateRadio === exactExplanationOptions.EXPLANATION && (
        <div className="row" style={{ marginLeft: "10px" }}>
          <div className="col-md-9">
            <label
              className="customer-complaint-label"
              htmlFor="civLitServedDateExplanation"
              id="civLitServedDateExplanationLabelId"
            >
              If not exact, provide explanation *
            </label>
            <textarea
              type="text"
              id="civLitServedDateExplanation"
              className={
                civLitServedDateExplanationError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={civLitServedDateExplanation}
              onChange={onChangeTextInput}
              onBlur={onBlurTextInput}
              onFocus={() => setFocusedState(true)}
            />

            <div
              id="civLitServedDateExplanationError"
              className="invalid-feedback"
            >
              Please enter an Explanation
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <label id="civLitIsPendingLabel" className="customer-complaint-label">
            Is the civil litigation pending? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="civLitIsPendingYes"
                type="radio"
                id="civLitIsPendingYes"
                className="custom-control-input"
                value={yesNoOptions.YES}
                checked={civLitIsPending === yesNoOptions.YES}
                onChange={handleIsPendingChangeChecked}
              />
              <label
                htmlFor="civLitIsPendingYes"
                className="custom-control-label"
                id="civLitIsPendingYesLabelId"
              >
                {yesNoOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="civLitIsPendingNo"
                type="radio"
                id="civLitIsPendingNo"
                className="custom-control-input"
                value={yesNoOptions.NO}
                checked={civLitIsPending === yesNoOptions.NO}
                onChange={handleIsPendingChangeChecked}
              />
              <label
                htmlFor="civLitIsPendingNo"
                className="custom-control-label"
                id="civLitIsPendingNoLabelId"
              >
                {yesNoOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      {civLitIsPending === yesNoOptions.NO && (
        <>
          <div className="row" style={{ marginLeft: "35px" }}>
            <div className="col-md-9" style={{ minWidth: "430px" }}>
              <label
                className="customer-complaint-label"
                htmlFor="civLitDispositionOption"
                id="civLitDispositionOptionLabelId"
              >
                If the civil litigation is not pending, what was the
                disposition? *
              </label>
              <div>
                <DropdownButton
                  id="civLitDispositionOption"
                  type="default"
                  variant="secondary"
                  title={
                    civLitDispositionOption === ""
                      ? "Status"
                      : civLitDispositionOption
                  }
                  className={
                    civLitDispositionOptionError
                      ? `is-invalid`
                      : civLitDispositionOption === ""
                      ? "status-placeholder"
                      : ""
                  }
                  selected={false}
                  autoComplete="new-address"
                  onSelect={(key, event) => {
                    event.target.id = "civLitDispositionOption";
                    event.target.value = key;
                    onChangeStatusOptionHandler(event);
                  }}
                  onBlur={onBlurDispositionOptionHandler}
                  onClick={clickFunc}
                >
                  {civLitStatusOptions.map(({ option }, key) => {
                    return (
                      <Dropdown.Item
                        key={key}
                        eventKey={option}
                        id={`civLitDispositionOption${key}`}
                      >
                        {option}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
              <div
                id="civLitDispositionOptionError"
                className={
                  civLitDispositionOptionError
                    ? "show invalid-feedback"
                    : "invalid-feedback"
                }
              >
                Please choose a disposition
              </div>
            </div>
          </div>
          {civLitDispositionOption === "Other" && (
            <div className="row" style={{ marginLeft: "35px" }}>
              <div className="col-md-9">
                <label
                  className="customer-complaint-label"
                  htmlFor="civLitDispositionOptionOtherExplanation"
                  id="civLitDispositionOptionOtherExplanationLabelId"
                >
                  Please provide explanation *
                </label>
                <textarea
                  type="text"
                  id="civLitDispositionOptionOtherExplanation"
                  className={
                    civLitDispositionOptionOtherExplanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={civLitDispositionOptionOtherExplanation}
                  onChange={onChangeTextInput}
                  onBlur={onBlurTextInput}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="civLitDispositionOptionOtherExplanationError"
                  className="invalid-feedback"
                >
                  Please enter a response
                </div>
              </div>
            </div>
          )}
          {(civLitDispositionOption === "Settled" ||
            civLitDispositionOption.includes("Monetary")) && (
            <>
              <div className="row" style={{ marginLeft: "35px" }}>
                <div className="col-md-10">
                  <label
                    className="customer-complaint-label"
                    htmlFor="civLitMonetaryComp"
                    id="civLitMonetaryCompLabelId"
                  >
                    Monetary Compensation Details (award settlement, reparation
                    amount) *
                  </label>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "35px" }}>
                <div className="col-md-5">
                  <div
                    className={
                      civLitMonetaryCompError
                        ? "input-group is-invalid"
                        : "input-group"
                    }
                    id="alleged_damage_amount_input"
                  >
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-pad-right"
                        id="alleged_damage_amount_input_$"
                      >
                        $
                      </span>
                    </div>
                    <input
                      type="text"
                      id="civLitMonetaryComp"
                      className="form-control form-control-15"
                      maxLength={11}
                      value={civLitMonetaryComp}
                      onChange={onChangeTextInput}
                      onBlur={onBlurMoneyInput}
                      onFocus={() => setFocusedState(true)}
                      onKeyPress={onlyNumeric}
                      onPaste={pasteCommaDelimited}
                      autoComplete="off"
                    />
                  </div>
                  <div
                    id="civLitMonetaryCompError"
                    className={
                      civLitMonetaryCompError
                        ? "show invalid-feedback"
                        : "invalid-feedback"
                    }
                  >
                    {civLitMonetaryCompErrorMsg}
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "35px" }}>
                <div className="col-md-5">
                  <label
                    className="customer-complaint-label"
                    htmlFor="civLitContribAmount"
                    id="civLitContribAmountLabelId"
                  >
                    Your Contribution Amount *
                  </label>
                </div>
              </div>
              <div className="row" style={{ marginLeft: "35px" }}>
                <div className="col-md-5">
                  <div
                    className={
                      civLitContribAmountError
                        ? "input-group is-invalid"
                        : "input-group"
                    }
                    id="alleged_damage_amount_input"
                  >
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-pad-right"
                        id="alleged_damage_amount_input_$"
                      >
                        $
                      </span>
                    </div>
                    <input
                      type="text"
                      id="civLitContribAmount"
                      className="form-control form-control-15"
                      maxLength={11}
                      value={civLitContribAmount}
                      onChange={onChangeTextInput}
                      onBlur={onBlurMoneyInput}
                      onFocus={() => setFocusedState(true)}
                      onKeyPress={onlyNumeric}
                      onPaste={pasteCommaDelimited}
                      autoComplete="off"
                    />
                  </div>
                  <div
                    id="civLitContribAmountError"
                    className={
                      civLitContribAmountError
                        ? "show invalid-feedback"
                        : "invalid-feedback"
                    }
                  >
                    {civLitContribAmountErrorMsg}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <div className="row date-wrapper">
        <div className="col-md-9">
          <label
            className="customer-complaint-label"
            htmlFor="civLitDispositionDate"
            id="civLitDispositionDateLabelId"
          >
            Disposition Date: *
          </label>
          <InputMask
            id="civLitDispositionDate"
            mask="99/99/9999"
            maskChar="_"
            className={
              civLitDispositionDateError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            placeholder="MM/DD/YYYY"
            autoComplete="off"
            value={civLitDispositionDate}
            onChange={onChangeTextInput}
            onBlur={onBlurDateInput}
            onFocus={() => setFocusedState(true)}
          />
          <div id="civLitDispositionDateError" className="invalid-feedback">
            {civLitDispositionDateErrorMsg}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="civLitDispositionDateRadioExact"
                type="radio"
                id="civLitDispositionDateRadioExact"
                className="custom-control-input"
                value={exactExplanationOptions.EXACT}
                checked={
                  civLitDispositionDateRadio === exactExplanationOptions.EXACT
                }
                onChange={handleDispositionDateRadioCheckChange}
              />
              <label
                htmlFor="civLitDispositionDateRadioExact"
                className="custom-control-label"
                id="civLitDispositionDateRadioExactLabelId"
              >
                {exactExplanationOptions.EXACT} date
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="civLitDispositionDateRadioExplanation"
                type="radio"
                id="civLitDispositionDateRadioExplanation"
                className="custom-control-input"
                value={exactExplanationOptions.EXPLANATION}
                checked={
                  civLitDispositionDateRadio ===
                  exactExplanationOptions.EXPLANATION
                }
                onChange={handleDispositionDateRadioCheckChange}
              />
              <label
                htmlFor="civLitDispositionDateRadioExplanation"
                className="custom-control-label"
                id="civLitDispositionDateRadioExplanationLabelId"
              >
                {exactExplanationOptions.EXPLANATION}
              </label>
            </div>
          </div>
        </div>
      </div>
      {civLitDispositionDateRadio === exactExplanationOptions.EXPLANATION && (
        <div className="row" style={{ marginLeft: "10px" }}>
          <div className="col-md-10">
            <label
              className="customer-complaint-label"
              htmlFor="civLitDispositionDateExplanation"
              id="civLitDispositionDateExplanationLabelId"
            >
              If not exact, provide explanation *
            </label>
            <textarea
              type="text"
              id="civLitDispositionDateExplanation"
              className={
                civLitDispositionDateExplanationError
                  ? "form-control form-control-15 is-invalid"
                  : "form-control form-control-15"
              }
              maxLength={500}
              minLength={2}
              autoComplete="off"
              value={civLitDispositionDateExplanation}
              onChange={onChangeTextInput}
              onBlur={onBlurTextInput}
              onFocus={() => setFocusedState(true)}
            />
            <div
              id="civLitDispositionDateExplanationError"
              className="invalid-feedback"
            >
              Please enter an Explanation
            </div>
          </div>
        </div>
      )}
      <hr></hr>
      <div className="row">
        <div className="col-md-12">
          <label
            id="civLitIsOnAppealLabel"
            className="customer-complaint-label"
          >
            Is the action currently on appeal? *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4">
            <div className="custom-control custom-radio">
              <input
                name="civLitIsOnAppealYes"
                type="radio"
                id="civLitIsOnAppealYes"
                className="custom-control-input"
                value={yesNoOptions.YES}
                checked={civLitIsOnAppeal === yesNoOptions.YES}
                onChange={handleIsOnAppealChangeChecked}
              />
              <label
                htmlFor="civLitIsOnAppealYes"
                className="custom-control-label"
                id="civLitIsOnAppealYesLabelId"
              >
                {yesNoOptions.YES}
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                name="civLitIsOnAppealNo"
                type="radio"
                id="civLitIsOnAppealNo"
                className="custom-control-input"
                value={yesNoOptions.NO}
                checked={civLitIsOnAppeal === yesNoOptions.NO}
                onChange={handleIsOnAppealChangeChecked}
              />
              <label
                htmlFor="civLitIsOnAppealNo"
                className="custom-control-label"
                id="civLitIsOnAppealNoLabelId"
              >
                {yesNoOptions.NO}
              </label>
            </div>
          </div>
        </div>
      </div>
      {civLitIsOnAppeal === yesNoOptions.YES && (
        <>
          <div className="row date-wrapper">
            <div className="col-md-9">
              <label
                className="customer-complaint-label"
                htmlFor="civLitAppealFileDate"
                id="civLitAppealFileDateLabelId"
              >
                Date appeal filed: *
              </label>
              <InputMask
                id="civLitAppealFileDate"
                mask="99/99/9999"
                maskChar="_"
                className={
                  civLitAppealFileDateError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                placeholder="MM/DD/YYYY"
                autoComplete="off"
                value={civLitAppealFileDate}
                onChange={onChangeTextInput}
                onBlur={onBlurDateInput}
                onFocus={() => setFocusedState(true)}
              />
              <div id="civLitAppealFileDateError" className="invalid-feedback">
                {civLitAppealFileDateErrorMsg}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4">
                <div className="custom-control custom-radio">
                  <input
                    name="civLitAppealFileDateRadioExact"
                    type="radio"
                    id="civLitAppealFileDateRadioExact"
                    className="custom-control-input"
                    value={exactExplanationOptions.EXACT}
                    checked={
                      civLitAppealFileDateRadio ===
                      exactExplanationOptions.EXACT
                    }
                    onChange={handleAppealDateRadioCheckChange}
                  />
                  <label
                    htmlFor="civLitAppealFileDateRadioExact"
                    className="custom-control-label"
                    id="civLitAppealFileDateRadioExactLabelId"
                  >
                    {exactExplanationOptions.EXACT} date
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="civLitAppealFileDateRadioExplanation"
                    type="radio"
                    id="civLitAppealFileDateRadioExplanation"
                    className="custom-control-input"
                    value={exactExplanationOptions.EXPLANATION}
                    checked={
                      civLitAppealFileDateRadio ===
                      exactExplanationOptions.EXPLANATION
                    }
                    onChange={handleAppealDateRadioCheckChange}
                  />
                  <label
                    htmlFor="civLitAppealFileDateRadioExplanation"
                    className="custom-control-label"
                    id="civLitAppealFileDateRadioExplanationLabelId"
                  >
                    {exactExplanationOptions.EXPLANATION}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {civLitAppealFileDateRadio ===
            exactExplanationOptions.EXPLANATION && (
            <div className="row" style={{ marginLeft: "10px" }}>
              <div className="col-md-10">
                <label
                  className="customer-complaint-label"
                  htmlFor="civLitAppealFileDateExplanation"
                  id="civLitAppealFileDateExplanationLabelId"
                >
                  If not exact, provide explanation *
                </label>
                <textarea
                  type="text"
                  id="civLitAppealFileDateExplanation"
                  className={
                    civLitAppealFileDateExplanationError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={civLitAppealFileDateExplanation}
                  onChange={onChangeTextInput}
                  onBlur={onBlurTextInput}
                  onFocus={() => setFocusedState(true)}
                />
                <div
                  id="civLitAppealFileDateExplanationError"
                  className="invalid-feedback"
                >
                  Please enter an Explanation
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <label className="customer-complaint-label">
                Court appeal filed in: *
              </label>
            </div>
          </div>
          <div className="row">
            <div className="drp-radio-input-wrapper">
              <div className="col-12 copy-md-4 form-group">
                <div className="custom-control custom-radio">
                  <input
                    name="appealFederalCourt"
                    type="radio"
                    id="appealFederalCourt"
                    className="custom-control-input"
                    value={courtType.FEDERAL}
                    checked={civLitAppealCourtType === courtType.FEDERAL}
                    onChange={handleAppealChangeChecked}
                  />
                  <label
                    htmlFor="appealFederalCourt"
                    className="custom-control-label"
                    id="appealFederalCourtLabelId"
                  >
                    {courtType.FEDERAL}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="appealStateCourt"
                    type="radio"
                    id="appealStateCourt"
                    className="custom-control-input"
                    value={courtType.STATE}
                    checked={civLitAppealCourtType === courtType.STATE}
                    onChange={handleAppealChangeChecked}
                  />
                  <label
                    htmlFor="appealStateCourt"
                    className="custom-control-label"
                    id="appealStateCourtLabelId"
                  >
                    {courtType.STATE}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="appealForeignCourt"
                    type="radio"
                    id="appealForeignCourt"
                    className="custom-control-input"
                    value={courtType.FOREIGN}
                    checked={civLitAppealCourtType === courtType.FOREIGN}
                    onChange={handleAppealChangeChecked}
                  />
                  <label
                    htmlFor="appealForeignCourt"
                    className="custom-control-label"
                    id="appealForeignCourtLabelId"
                  >
                    {courtType.FOREIGN}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="appealMilitaryCourt"
                    type="radio"
                    id="appealMilitaryCourt"
                    className="custom-control-input"
                    value={courtType.MILITARY}
                    checked={civLitAppealCourtType === courtType.MILITARY}
                    onChange={handleAppealChangeChecked}
                  />
                  <label
                    htmlFor="appealMilitaryCourt"
                    className="custom-control-label"
                    id="appealMilitaryCourtLabelId"
                  >
                    {courtType.MILITARY}
                  </label>
                </div>

                <div className="custom-control custom-radio">
                  <input
                    name="appealOtherRadio"
                    type="radio"
                    id="appealOtherRadio"
                    className="custom-control-input"
                    value={courtType.OTHER}
                    checked={civLitAppealCourtType === courtType.OTHER}
                    onChange={handleAppealChangeChecked}
                  />
                  <label
                    htmlFor="appealOtherRadio"
                    className="custom-control-label"
                    id="appealOtherRadioLabelId"
                  >
                    {courtType.OTHER}
                  </label>
                </div>

                {civLitAppealCourtType === courtType.OTHER ? (
                  <div
                    className="court-action-other"
                    style={{ marginLeft: "25px" }}
                  >
                    <input
                      type="text"
                      id="appealOtherText"
                      className={
                        civLitAppealFileCourtTypeOtherError
                          ? "form-control form-control-15 is-invalid"
                          : "form-control form-control-15"
                      }
                      maxLength={500}
                      minLength={2}
                      autoComplete="off"
                      value={civLitAppealFileCourtTypeOther}
                      onChange={onChangeTextInput}
                      onBlur={onBlurTextInput}
                      onFocus={() => setFocusedState(true)}
                    />
                    <div
                      id="civLitAppealFileCourtTypeOtherError"
                      className="invalid-feedback"
                    >
                      Please enter a response
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" style={{ minWidth: "430px" }}>
              <label
                className="customer-complaint-label"
                htmlFor="civLitAppealCourtName"
                id="civLitAppealCourtNameLabelId"
              >
                Name of court: *
              </label>
              <input
                type="text"
                id="civLitAppealCourtName"
                className={
                  civLitAppealCourtNameError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                maxLength={100}
                minLength={2}
                autoComplete="off"
                value={civLitAppealCourtName}
                onChange={onChangeTextInput}
                onBlur={onBlurTextInput}
                onFocus={() => setFocusedState(true)}
              />
              <div id="civLitAppealCourtNameError" className="invalid-feedback">
                Please enter Name of court
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" style={{ minWidth: "430px" }}>
              <label
                className="customer-complaint-label"
                htmlFor="civLitAppealCourtLocation"
                id="civLitAppealCourtLocationLabelId"
              >
                Location of court (City or County and State or Country): *
              </label>
              <input
                type="text"
                id="civLitAppealCourtLocation"
                className={
                  civLitAppealCourtLocationError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                maxLength={100}
                minLength={2}
                autoComplete="off"
                value={civLitAppealCourtLocation}
                onChange={onChangeTextInput}
                onBlur={onBlurTextInput}
                onFocus={() => setFocusedState(true)}
              />
              <div
                id="civLitAppealCourtLocationError"
                className="invalid-feedback"
              >
                Please enter Location of court
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" style={{ minWidth: "430px" }}>
              <label
                className="customer-complaint-label"
                htmlFor="civLitAppealDocketOrCase"
                id="civLitAppealDocketOrCaseLabelId"
              >
                Docket/Case #: *
              </label>
              <input
                type="text"
                id="civLitAppealDocketOrCase"
                className={
                  civLitAppealDocketOrCaseError
                    ? "form-control form-control-15 is-invalid"
                    : "form-control form-control-15"
                }
                maxLength={100}
                minLength={2}
                autoComplete="off"
                value={civLitAppealDocketOrCase}
                onChange={onChangeTextInput}
                onBlur={onBlurTextInput}
                onFocus={() => setFocusedState(true)}
              />
              <div
                id="civLitAppealDocketOrCaseError"
                className="invalid-feedback"
              >
                Please enter Docket/Case #
              </div>
            </div>
          </div>
        </>
      )}
      <div className="backbutton-drp-civ-lit row">
        <div className="col-md-12 buttons">
          <span onClick={handleShowBackModal}>
            <strong id="crimDisclBack">Back</strong>
          </span>
          <Button
            label="Next"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>
      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CustomerComplaintAddDRPCivilLitigationForm;
