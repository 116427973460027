/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-blueprint-library";
import { useDispatch } from "react-redux";

import { logoutActions } from "../../../../../../../store/actions";

import BackButtonModal from "../../../../../Forms/BackButtonModal";
import DRPCardNoButton from "../../../common/DRPCard/DRPCardNoButton";
import DRPSummaryListingCardCheck from "../../../common/DRPSummaryListingCard";

import "./CivJudActionDetailsForm.scss";
import { eventStatus } from "../CivJudStatusDetails";

const courtActionType = {
  SEC: "SEC",
  FEDERAL: "Other Federal Agency",
  JUR: "Jurisdiction",
  FOREIGN: "Foreign Financial Regulatory Authority",
  FIRM: "Firm",
  PRIV: "Private Plaintiff",
};

const formalActionType = {
  FEDERAL: "Federal Court",
  STATE: "State Court",
  FOREIGN: "Foreign Court",
  MILITARY: "Military Court",
  OTHER: "Other",
};

const CivJudActionDetailsForm = ({
  updateCCBForm,
  backFormId,
  nextFormId,
  values,
  getSubmitDataValues,
  data,
  groupKey,
  status,
  setSkipResAndSanctDetails,
  headerText,
}) => {
  const dispatch = useDispatch();
  const [drpCheckList, setDRPCheckList] = useState(
    data?.drpCheckList.length ? data?.drpCheckList : []
  );

  const [courtAction, setCourtAction] = useState(data?.courtAction || "");
  const [courtActionError, setCourtActionError] = useState(false);

  const [procName, setProcName] = useState(data?.procName || "");
  const [procNameError, setProcNameError] = useState(false);

  const [formalAction, setFormalAction] = useState(data?.formalAction || "");
  const [formalActionOther, setFormalActionOther] = useState(
    data?.formalActionOther || ""
  );
  const [formalActionError, setFormalActionError] = useState(false);

  const [courtName, setCourtName] = useState(data?.courtName || "");
  const [courtNameError, setCourtNameError] = useState(false);

  const [locationCourt, setLocationCourt] = useState(data?.locationCourt || "");
  const [locationCourtError, setLocationCourtError] = useState(false);

  const [docketOrCase, setDocketOrCase] = useState(data?.docketOrCase || "");
  const [docketOrCaseError, setDocketOrCaseError] = useState(false);

  const [formHasChanged, setFormHasChanged] = useState(false);
  const [showBackModal, setShowBackModal] = useState(false);
  const [isFocusedState, setFocusedState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    setSkipResAndSanctDetails(status === eventStatus.PENDING);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const isError =
    courtActionError ||
    formalActionError ||
    procNameError ||
    courtNameError ||
    docketOrCaseError ||
    locationCourtError;

  useEffect(() => {
    if (isError) {
      setFormHasChanged(true);
    }
  }, [isError]);

  const isNextBtnDisabled =
    !!(
      drpCheckList?.length <= 0 ||
      !courtAction?.length ||
      !formalAction?.length ||
      (formalAction?.length &&
        formalAction === formalActionType.OTHER &&
        !formalActionOther?.length) ||
      (formalAction?.length &&
        formalAction === formalActionType.OTHER &&
        formalActionOther?.length &&
        formalActionOther?.length < 2) ||
      !docketOrCase?.length ||
      (docketOrCase?.length && docketOrCase?.length < 2) ||
      !locationCourt?.length ||
      (locationCourt?.length && locationCourt?.length < 2) ||
      !courtName?.length ||
      (courtName?.length && courtName?.length < 2) ||
      !procName?.length ||
      (procName?.length && procName?.length < 2)
    ) ||
    isError ||
    isFocusedState;

  const handleCloseBackModal = () => setShowBackModal(false);

  const isUpdates = formHasChanged || isError;

  useEffect(() => {
    if (values.length && values.length === 1) {
      setDRPCheckList((drp) => [...drp, ...values.map((v) => v.key)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: isUpdates }));
  }, [isUpdates, dispatch]);

  const handleChangeCheckedCourtAction = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormHasChanged(true);

    setCourtAction(newValue);
    setCourtActionError(false);
  };

  const onChangeProcNameHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setProcName(value);
  };

  const onBlurProcNameHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setProcNameError(true)
      : setProcNameError(false);
  };

  const onChangeCourtNameHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setCourtName(value);
  };

  const onBlurCourtNameHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setCourtNameError(true)
      : setCourtNameError(false);
  };

  const onChangeFormalActionHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setFormalActionOther(value);
  };
  const onBlurFormalActionHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value?.trim()?.length < 2 || value.trim()?.length > 500
      ? setFormalActionError(true)
      : setFormalActionError(false);
  };

  const handleChangeCheckedFormalAction = (e) => {
    const { value } = e.target;
    let newValue = value;

    setFormalActionError(false);
    setFormHasChanged(true);

    if (value !== formalActionType.OTHER) {
      setFormalActionOther("");
    }

    setFormalAction(newValue);
  };

  const onChangeLocationCourtHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setLocationCourt(value);
  };

  const onBlurLocationCourtHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setLocationCourtError(true)
      : setLocationCourtError(false);
  };

  const onChangeDocketOrCaseHandler = ({ target: { value } }) => {
    setFormHasChanged(true);
    setDocketOrCase(value);
  };

  const onBlurDocketOrCaseHandler = ({ target: { value } }) => {
    setFocusedState(false);
    return value.trim().length < 2 || value.trim().length > 100
      ? setDocketOrCaseError(true)
      : setDocketOrCaseError(false);
  };

  const handleShowBackModal = () => {
    if (isUpdates) {
      setShowBackModal(true);
    } else {
      if (
        (data !== null && data && !Object.keys(data).length) ||
        data === null
      ) {
        getSubmitDataValues({ [groupKey]: null }, headerText);
      }
      updateCCBForm(backFormId);
    }
  };

  const handleBackClick = () => {
    if ((data !== null && data && !Object.keys(data).length) || data === null) {
      getSubmitDataValues({ [groupKey]: null }, headerText);
    }
    dispatch(logoutActions.setShowLogoutModal({ showLogoutModal: false }));
    updateCCBForm(backFormId);
    handleCloseBackModal();
  };

  const handleNext = useCallback(() => {
    const submitData = {
      drpCheckList: [...new Set(drpCheckList)],
      courtAction,
      procName,
      formalAction,
      formalActionOther,
      courtName,
      locationCourt,
      docketOrCase,
    };

    setFormHasChanged(false);

    if (values?.length) {
      submitData.drpCheckList = [...new Set(drpCheckList || [])];
    }

    getSubmitDataValues({ [`${groupKey}`]: submitData }, headerText);

    updateCCBForm(nextFormId);
  }, [
    updateCCBForm,
    drpCheckList,
    nextFormId,
    headerText,
    procName,
    courtName,
    locationCourt,
    formalAction,
    docketOrCase,
    formalActionOther,
    getSubmitDataValues,
    groupKey,
    courtAction,
    values,
  ]);

  const handleChange = ({ target: { checked, value } }) => {
    const list = [];

    setFormHasChanged(true);

    if (checked) {
      list.push(value);

      setDRPCheckList((drp) => [...drp, ...list]);
    }

    if (!checked) {
      setDRPCheckList((drp) => drp?.filter((item) => item !== value));
    }
  };

  return (
    <div className="civ-jud-details-form-wrapper">
      <div className="crim-discl-drp-summary">
        {values?.length && values.length > 1 ? (
          <DRPCardNoButton
            header={"Check the question(s) you are responding to:"}
            isDrp={values?.length > 4}
          >
            {values.map(({ key, mainQuestion, question }, index) => (
              <DRPSummaryListingCardCheck
                key={`${index}_${key}`}
                questionKey={key}
                mainQuestion={mainQuestion}
                question={question}
                index={index}
                length={values.length}
                handleChange={handleChange}
                drpCheckList={drpCheckList}
                isCheckbox
              />
            ))}
          </DRPCardNoButton>
        ) : null}
      </div>

      <div className="row">
        <div className="col-md-12">
          <label id="civCourtAction" className="court-action">
            Court action initiated by: *
          </label>
        </div>
      </div>

      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="actionSec"
                type="radio"
                id="actionSecId"
                className="custom-control-input"
                value={courtActionType.SEC}
                checked={courtAction === courtActionType.SEC}
                onChange={handleChangeCheckedCourtAction}
              />
              <label
                htmlFor="actionSecId"
                className="custom-control-label"
                id="actionSecLabelId"
              >
                {courtActionType.SEC}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionSRO"
                type="radio"
                id="actionSROId"
                className="custom-control-input"
                value={courtActionType.FEDERAL}
                checked={courtAction === courtActionType.FEDERAL}
                onChange={handleChangeCheckedCourtAction}
              />
              <label
                htmlFor="actionSROId"
                className="custom-control-label"
                id="actionSROLabelId"
              >
                {courtActionType.FEDERAL}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionJUR"
                type="radio"
                id="actionJURId"
                className="custom-control-input"
                value={courtActionType.JUR}
                checked={courtAction === courtActionType.JUR}
                onChange={handleChangeCheckedCourtAction}
              />
              <label
                htmlFor="actionJURId"
                className="custom-control-label"
                id="actionJURLabelId"
              >
                {courtActionType.JUR}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionFederalFOREIGN"
                type="radio"
                id="actionFederalFOREIGNId"
                className="custom-control-input"
                value={courtActionType.FOREIGN}
                checked={courtAction === courtActionType.FOREIGN}
                onChange={handleChangeCheckedCourtAction}
              />
              <label
                htmlFor="actionFederalFOREIGNId"
                className="custom-control-label"
                id="actionFederalFOREIGNLabelId"
              >
                {courtActionType.FOREIGN}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionStateFIRM"
                type="radio"
                id="actionStateFIRMId"
                className="custom-control-input"
                value={courtActionType.FIRM}
                checked={courtAction === courtActionType.FIRM}
                onChange={handleChangeCheckedCourtAction}
              />
              <label
                htmlFor="actionStateFIRMId"
                className="custom-control-label"
                id="actionStateFIRMLabelId"
              >
                {courtActionType.FIRM}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionPRIV"
                type="radio"
                id="actionPRIVId"
                className="custom-control-input"
                value={courtActionType.PRIV}
                checked={courtAction === courtActionType.PRIV}
                onChange={handleChangeCheckedCourtAction}
              />
              <label
                htmlFor="actionPRIVId"
                className="custom-control-label"
                id="actionPRIVLabelID"
              >
                {courtActionType.PRIV}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row proc-name">
        <div className="col-md-6">
          <label className="org-name" htmlFor="procNameId" id="procNameLabelId">
            Name of party initiating the proceeding: *
          </label>
          <input
            type="text"
            id="procNameId"
            className={
              procNameError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            minLength={2}
            maxLength={100}
            autoComplete="off"
            value={procName}
            onChange={onChangeProcNameHandler}
            onBlur={onBlurProcNameHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="procNameError" className="invalid-feedback">
            Please enter Name of party initiating the proceeding
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <label id="civFormalActionLabelId" className="court-action">
            Formal action was brought in: *
          </label>
        </div>
      </div>
      <div className="row">
        <div className="drp-radio-input-wrapper">
          <div className="col-12 copy-md-4 form-group">
            <div className="custom-control custom-radio">
              <input
                name="actionFormal"
                type="radio"
                id="actionFormalId"
                className="custom-control-input"
                value={formalActionType.FEDERAL}
                checked={formalAction === formalActionType.FEDERAL}
                onChange={handleChangeCheckedFormalAction}
              />
              <label
                htmlFor="actionFormalId"
                className="custom-control-label"
                id="federalLabelId"
              >
                {formalActionType.FEDERAL}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionState"
                type="radio"
                id="actionStateFromalId"
                className="custom-control-input"
                value={formalActionType.STATE}
                checked={formalAction === formalActionType.STATE}
                onChange={handleChangeCheckedFormalAction}
              />
              <label
                htmlFor="actionStateFromalId"
                className="custom-control-label"
                id="stateCourtLabelId"
              >
                {formalActionType.STATE}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionForeignFormal"
                type="radio"
                id="actionForeignFromalId"
                className="custom-control-input"
                value={formalActionType.FOREIGN}
                checked={formalAction === formalActionType.FOREIGN}
                onChange={handleChangeCheckedFormalAction}
              />
              <label
                htmlFor="actionForeignFromalId"
                className="custom-control-label"
                id="actionForeignFromalLabelId"
              >
                {formalActionType.FOREIGN}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="actionMil"
                type="radio"
                id="actionMilId"
                className="custom-control-input"
                value={formalActionType.MILITARY}
                checked={formalAction === formalActionType.MILITARY}
                onChange={handleChangeCheckedFormalAction}
              />
              <label
                htmlFor="actionMilId"
                className="custom-control-label"
                id="actionMilLabelId"
              >
                {formalActionType.MILITARY}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                name="otherRadio"
                type="radio"
                id="otherRadioFormalId"
                className="custom-control-input"
                value={formalActionType.OTHER}
                checked={formalAction === formalActionType.OTHER}
                onChange={handleChangeCheckedFormalAction}
              />
              <label
                htmlFor="otherRadioFormalId"
                className="custom-control-label"
                id="otherRadioLabelId"
              >
                {formalActionType.OTHER}
              </label>
            </div>

            {formalAction === formalActionType.OTHER ? (
              <div className="court-action-other">
                <input
                  type="text"
                  id="otherText"
                  className={
                    formalActionError
                      ? "form-control form-control-15 is-invalid"
                      : "form-control form-control-15"
                  }
                  maxLength={500}
                  minLength={2}
                  autoComplete="off"
                  value={formalActionOther}
                  onChange={onChangeFormalActionHandler}
                  onBlur={onBlurFormalActionHandler}
                  onFocus={() => setFocusedState(true)}
                />
                <div id="formalActionError" className="invalid-feedback">
                  Please enter a response
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="row proc-name">
        <div className="col-md-6">
          <label
            className="org-name"
            htmlFor="courtNameId"
            id="courtNameLabelId"
          >
            Name of Court: *
          </label>
          <input
            type="text"
            id="courtNameId"
            className={
              courtNameError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            minLength={2}
            maxLength={100}
            autoComplete="off"
            value={courtName}
            onChange={onChangeCourtNameHandler}
            onBlur={onBlurCourtNameHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="courtNameError" className="invalid-feedback">
            Please enter Name of Court
          </div>
        </div>
      </div>

      <div className="row proc-name">
        <div className="col-md-6">
          <label
            className="org-name"
            htmlFor="locationCourtId"
            id="locationCourtLabelId"
          >
            Location of Court (City or County or State or Country): *
          </label>
          <input
            type="text"
            id="locationCourtId"
            className={
              locationCourtError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            minLength={2}
            maxLength={100}
            autoComplete="off"
            value={locationCourt}
            onChange={onChangeLocationCourtHandler}
            onBlur={onBlurLocationCourtHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="locationCourtError" className="invalid-feedback">
            Please enter Location of Court
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <label
            className="org-name"
            htmlFor="docketCaseId"
            id="docketCaseLabelId"
          >
            Docket/Case #: *
          </label>
          <input
            type="text"
            id="docketCaseId"
            className={
              docketOrCaseError
                ? "form-control form-control-15 is-invalid"
                : "form-control form-control-15"
            }
            minLength={2}
            maxLength={100}
            autoComplete="off"
            value={docketOrCase}
            onChange={onChangeDocketOrCaseHandler}
            onBlur={onBlurDocketOrCaseHandler}
            onFocus={() => setFocusedState(true)}
          />
          <div id="docketOrCaseError" className="invalid-feedback">
            Please enter Docket/Case #
          </div>
        </div>
      </div>

      <div className="backbutton-drp-crim row">
        <div className="col-md-12 buttons">
          <span
            id="civBackId"
            role="presentation"
            onClick={handleShowBackModal}
          >
            <strong id="civBackLabelId">Back</strong>
          </span>
          <Button
            label="Next"
            id="civNextId"
            onClick={handleNext}
            disabled={!!isNextBtnDisabled}
          />
          {false && <span className="mm-dots-loading"></span>} {/* loading */}
        </div>
      </div>

      <BackButtonModal
        isOpen={showBackModal}
        closeBackModal={handleCloseBackModal}
        backClick={handleBackClick}
      />
    </div>
  );
};

export default CivJudActionDetailsForm;
